import React from "react";
import "./CarpetSteamOptions.css";
import { RadioDropOption } from "..";

const CarpetSteamOptions = () => {
  const OPTIONS = [
    {
      header: "Bedroom < 12 sqm",
    },
    {
      header: "Dining area < 25 sqm",
    },
    {
      header: "Hallway < 3 metres",
    },
    {
      header: "Study Room",
    },
    {
      header: "Walk in Robe",
    },
    {
      header: "Living area < 25 sqm",
    },
    {
      header: "Staircase (15 stairs max)",
    },
    {
      header: "Combined Lounge / Dining < 30sqm",
    },
  ];

  return (
    <div className="carpetSteamOptions">
      {OPTIONS.map(({ header }, index) => (
        <RadioDropOption key={index} header={header} />
      ))}
    </div>
  );
};

export default CarpetSteamOptions;
