import React, { useState } from "react";
import "./Document.css";
import { Link } from "react-router-dom";
import { Record } from "../../Dashboard";
import { InfoCircleFilled } from "@ant-design/icons";
import { useAuth } from "../../../../contexts/authcontexts";

const Document = ({
  header,
  subHeader = "Date Generated",
  datas,
  isDocument,
  isSite,
  isInvoice,
}) => {
  const { currentUser } = useAuth();
  const [visibledata, setVisibledata] = useState(10);
  const handleShowmore = () => {
    setVisibledata((prevValue) => prevValue + 10);
  };
  console.log("this is the main table ");
  // console.log(datas,"datas in the document")
  return (
    <div className="document">
      <div className="document__body">
        <div className="document__bodyHead">
          <p>{header}</p>
          <p>{subHeader}</p>
          <p>Options</p>
        </div>
        {isDocument && (
          <ul>
            {datas
              .slice(0, visibledata)
              .map(({ docType, date, id, url }, index) => (
                <li key={index}>
                  <Record name={docType} date={date} uid={id} url={url} />
                </li>
              ))}
          </ul>
        )}
        {isSite && (
          <ul>
            {datas.slice(0, visibledata).map(({ sitename }, index) => (
              <li key={index}>
                <Record name={sitename} date="-" isSite={isSite} />
              </li>
            ))}
          </ul>
        )}
        {isInvoice && (
          <ul>
            {datas.slice(0, visibledata).map(({ sitename }, index) => (
              <li key={index}>
                <Record name={sitename} date="-" isSite={isSite} />
              </li>
            ))}
          </ul>
        )}
      </div>

      {isDocument && (
        <div className="document__footer">
          <InfoCircleFilled style={{ color: "#0f8015" }} />
          <p>
            To Upload Documents Goto
            <Link to={`/${currentUser.claims.role}/dashboard/upload`}>
              Upload Documents
            </Link>
            Section
          </p>
        </div>
      )}

      <div className="document_showmore">
        <button className="document_showmore_button" onClick={handleShowmore}>
          Show more
        </button>
      </div>
    </div>
  );
};

export default Document;
