import React from "react";
import MessageEditor from "./../../MessageEditor/MessageEditor";
import DashboardHeader from "./../../DashboardHeader/DashboardHeader";

const RequestSupervision = (props) => {
    
    
  return (
    <>
      <div className="request_service">
        <DashboardHeader
          header="Request Supervision"
          goBack={props.history.push}
          isNested
        />
        <MessageEditor requestType="Request Supervision Service"/>

      </div>
    </>
  );
};

export default RequestSupervision;
