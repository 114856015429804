import React from "react";
import "./TopClients.css";
import client1 from "../../../assets/client_1.png";
import client2 from "../../../assets/client_2.png";
import client3 from "../../../assets/client_3.png";
import client4 from "../../../assets/client_4.png";
import client5 from "../../../assets/client_5.png";
import client6 from "../../../assets/client_6.png";

const TopClients = () => {
  return (
    <div className="topClients">
      <div className="content">
        <div className="topClients__header">
          <h3>
            OUR <span>TOP</span> CLIENTS
          </h3>
        </div>
        <div className="topClients__body">
          <div className="topClients__image">
            <img src={client5} alt="client" />
          </div>
          <div className="topClients__image">
            <img src={client6} alt="client" />
          </div>
          <div className="topClients__image">
            <img src={client2} alt="client" />
          </div>
          <div className="topClients__image">
            <img src={client1} alt="client" />
          </div>
          <div className="topClients__image">
            <img src={client3} alt="client" />
          </div>
          <div className="topClients__image">
            <img src={client4} alt="client" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopClients;
