import {
  FaCity,
  FaBoxOpen,
  FaDumbbell,
  FaUniversity,
  FaBriefcase,
  FaClinicMedical,
  FaPaintRoller,
  FaWindows,
  FaShoppingCart,
  FaShippingFast,
  FaBlind,
  FaRecycle,
  FaAirbnb,
  FaMugHot,
  FaWarehouse,
} from "react-icons/all";

const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
};

const images = importAll(
  require.context("../assets/servicesImg", false, /\.(png|jpe?g|svg)$/)
);
// console.log(images);
// console.log(images["service_img1.jpg"].default);

export const SERVICES = [
  {
    slug: "strata-body-corporate-cleaning",
    Icon: FaCity,
    title: "Strata / Body Corporate Cleaning",
    description: `There are two things that truly matter when searching for body corporate cleaning administrations. The main interesting point is the organization's productivity in cleaning your body corporate area, and the second concerns the nature of the administration gave. A few layers cleaning administrations in Melbourne and Sydney can just convey either, while we Green Commercial Cleaning can give both a quality and proficient cleaning administration.
      As we know that your dignity is under line while running a company and it will be time consuming to maintain clean environment so we green work precisely to maintain that.
      Different company provides different kind of strata cleaning services and sometimes they fall for false attraction and end up choosing wrong service provider which leads them to serious problem.`,
    offerTitle: "What we offer to our customer",
    contentTitle: `Different company provides different kind of strata cleaning
      services and sometimes they fall for false attraction and end up
      choosing wrong service provider which leads them to serious
      problem. So, we offer our client several advantages to make them
      happy. We offer:`,
    secondContentTitle:
      "Along with above advantages, we provide you following term:",
    contentList: [
      "Window cleaning.",
      "Garden maintenance.",
      "Tile and floor cleaning.",
      "Emergency cleaning.",
      "Regular body corporate maintenance and cleaning services.",
      "Regular steam and dry cleaning for upholstery.",
      "Emergency flood restoration.",
      "Regular carpet steam and dry cleaning.",
      "Supply of consumables such as toilet paper.",
    ],
    offerList: [
      "Our organization is authorized and ensured so you can confide in us to adhere to industry gauges with regards to Strata cleaning administrations in Melbourne.",
      "We just use ISO confirmed cleaning items on occupations so you can be certain even the touchiest occupants are sheltered.",
      "Every one of our cleaners experience far reaching record verifications. You can confide in them to enter your property without stressing over your occupants or their prosperity.",
      "We keep our costs sensible with no trade off in quality. You can be sure that your property will consistently be immaculately spotless.",
      "Our group can utilize green or eco-accommodating cleaning arrangements if necessary.",
    ],
    cover: images["body_corporate.jpg"].default,
    images: [
      images["body_corporate.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
  },
  {
    slug: "food-processing-unit-factory-cleaning",
    cover: images["factory_cleaning_cover.jpg"].default,
    images: [
      images["factory_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    Icon: FaBoxOpen,
    title: "Food Processing Unit / Factory Cleaning",
    description: `When it comes about industrial cleaning, it is about to clean the machinery objects and human resource leaning them to continue the effectiveness of the industry leading them to make more benefits. For those type of cleaning you can rely upon us “Green Commercial Cleaning” where our expertise makes sure to keep your resource clean so that the process goes on. Due to the dirty surrounding and unhealthy environment your industry can lack on the performance of human as well as machines. In order to maintain the stability in your work, making high level standards cleanliness you can rely upon us.`,
    offerTitle: "",
    contentTitle: "We can help with following industrial cleaning:",
    secondContentTitle: "",
    contentList: [
      "Factory Cleaning",
      "Hard Industrial Cleaning",
      "Soft Industrial Cleaning",
      "Manufacturing Cleaning",
    ],
    offerList: [],
  },
  {
    slug: "gym-fitness-center-cleaning",
    Icon: FaDumbbell,
    title: "Gym/Fitness Center Cleaning",
    description: `From change rooms, to floors, to regular territories and club rooms, Green Commercial Cleaning has the experience and the aptitudes to deal with the parcel. We can wipe up the perspiration and the tears and keep your whole premises shining for your individuals, onlookers and benefactors. As in the mean time of today’s generation the range of sporting and leisure centers in our community, we begin to acknowledge how assorted they are. Taking into account various gatherings and sports with an enormous and significant social viewpoint to consider, the cleaning prerequisites of these offices can be very changed.
      We can take care the cleaning for a full scope of wearing offices across the city. This includes open air regions, indoor zones, wet and dry regions with an emphasis on mindful cleaning strategies according to nature and word related wellbeing.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    images: [
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
  },
  {
    slug: "educational-institution-cleaning",
    Icon: FaUniversity,
    title: "Educational Institution Cleaning",
    description: `Green commercial cleaning a well-known cleaning company in Australia has already been working and demonstrated to have an enough experience upon cleaning small schools, large schools, private schools and public schools.
      As cleaning company, we understand the importance of healthy environment while reading for students. keeping mind on that our expertise make sure to clean every corner of premises. We've grown as good as ever methods of cleaning to guarantee that our goal is centered around expelling contaminants from your school and homerooms, as opposed to just redistributing them. We likewise have unique aptitude in cleaning other basic territories in the school including your staff rooms, can squares and play areas. Our staff are profoundly prepared to convey our cleaning administrations in light of your partners. Since we clean since we give it a second thought, you'll be sure that your understudies and educators are sheltered and secure with regards to the neatness of the school and the lead of our staff.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    cover: images["education_cleaning_cover.jpg"].default,
    images: [
      images["education_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
  },
  {
    slug: "office-cleaning",
    Icon: FaBriefcase,
    title: "Office Cleaning",
    description: `The success rate of your company based upon the appearance of your company. Messy and unorganized office premises lead to the bad impression over clients. So, hiring professional cleaning company in Melbourne i.e. green commercial will be great choice. As a green commercial cleaning we offer a best and quality cleaning services around Melbourne. Peace working environment with clean surrounding help to boost up the energy of employees and put great impact on clients for extending and making business deals. So, our expert cleaning team work hardly to clean your company, offices from window to carpet and floor to ceiling so that you always be healthy, happy and grow your company better than before.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    cover: images["office_cleaning.jpg"].default,
    images: [
      images["office_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
  },
  {
    slug: "medical-center-cleaning",
    Icon: FaClinicMedical,
    title: "Medical Center Cleaning",
    description: `Guaranteeing reliable high cleaning guidelines that forestall the spread of contamination has never been a higher priority than now. Our degree of specialization in cleaning for clinical offices joins surface purification and bio-danger cleaning conforming to severe clinical necessities. Our emphasis is on detail cleans. Better subtleties we realize will have any kind of effect to the wellbeing, security and prosperity of your patients, guests and staff. We do this since we trust it's imperative to take care of individuals, much the same as when you put everything on the line to deal with each individual patient.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    cover: images["medical_cleaning.jpg"].default,
    images: [
      images["medical_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
  },
  {
    slug: "post-builders-cleaning",
    Icon: FaPaintRoller,
    title: "Post Builders  Cleaning",
    description: `Are you in search for Building Management Service (BMS) who can integrate with your existing building and their setup your search ends hear as we provide best service that you can trust on us:

      Green offers a far-reaching scope of building the board and support administrations custom fitted to keep up and improve all features of your office, including the framework. Our very much prepared, proficient staff will deal with the structure and its property, permitting your association to concentrate on what you excel at – work your business. Green’s Building Management Services give outfitted and unarmed security watches, janitorial and housekeeping staff, grounds and arranging groups just as general structure upkeep administrations and property the executives.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    images: [
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
  },
  {
    slug: "windows-cleaning",
    Icon: FaWindows,
    title: "Windows Cleaning",
    images: [
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    description:
      "We are looking for competent candidate for role of senior manager who can supervise our worker during field work. You can start asap.",
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
  },
  {
    slug: "shopping-center-cleaning",
    Icon: FaShoppingCart,
    title: "Shopping Center Cleaning",
    description: `We have years of experience providing quality service for shopping mall which helps the customer to buy goods in healthy and clean environment with peace of mind as well as employees to work in clean surrounding being relax for the vulnerability to germs and bacteria.`,
    offerTitle: "",
    contentTitle: "We serve for following shopping center:",
    secondContentTitle: "",
    contentList: [
      "Shopping Malls and Complexes",
      "Supermarkets",
      "Department Stores",
      "Retail stores and outlets",
      "Strip malls",
    ],
    offerList: [],
    cover: images["shopping_cleaning_cover.jpg"].default,
    images: [
      images["shopping_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    
  },
  {
    slug: "end-of-lease-cleaning",
    Icon: FaShippingFast,
    title: "End of Lease Cleaning",
    images: [
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    description:
      "We are looking for competent candidate for role of senior manager who can supervise our worker during field work. You can start asap.",
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
  },
  {
    slug: "aged-care-cleaning",
    Icon: FaBlind,
    title: "Aged Care Cleaning",
    description: `Child care and retirement cleaning is sensible and far way from the daily commercial cleaning, as it includes human factors like care, compassion, empathy etc. We “Green Commercial Cleaning” understand how critical and confidential service is this. Germs can be spread quickly as small age child and older people are likely more vulnerable to virus due to their immune systems. Which should be maintain frequently and adequately if don’t so it might lead into the serious health problems. So, we overwhelmed standards hygiene, safety health and worked perfectly delivering an integrated & transparent age care and retirement cleaning across the city which is beyond compare.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    images: [
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    
  },
  {
    slug: "waste-management",
    Icon: FaRecycle,
    title: "Waste Management",
    description: `We green comercial cleaning company providing best cleaning services provide all kinds of waste management services. Firstly we comprehend customer necessities, guaranteeing that their waste administration vital objectives are comprehended and reached. We work with our customers to distinguish their waste creation; squander creation and waste age rates so we can assist them with shaping a help arrangement. We distinguish the best accessible advancements to treat squander and where we would we be able to increase the value of the squanders that are created through material reusing and reuse. We cautiously plan transportation prerequisites, ensuring that protected, agreeable and solid help execution is kept up.`,
    offerTitle: "",
    contentTitle:
      "The key principle we follow for management of the waste are:",
    secondContentTitle: "",
    contentList: [
      "Waste recovery, reuse and re-cycle",
      "Utilizing best available technologies",
      "Long term sustainable solutions",
    ],
    offerList: [],
    cover: images["waste_management_cover.jpg"].default,
    images: [
      images["waste_management.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    
  },
  {
    slug: "hospitality-cleaning",
    Icon: FaAirbnb,
    title: "Hospitality Cleaning",
    description: `We offer all kind of hospitality services, that will help to uplift your business and company and take it to the another level as the cleanliness of the venues and safety for your staff is vital to attract the client and customer. Indeed, our prosperity can be ascribed to the solid estimations of neighborliness that you would be comfortable with. We mean to make solid, enduring connections and we comprehend that we will assume a key job in the general understanding of your visitors and supporters when they visit your scene. You can believe that we assume this liability in protecting your premises, spotless and clean very truly.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    cover: images["hospitality_cleaning.jpg"].default,
    images: [
      images["hospitality_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    
  },
  {
    slug: "cafe-cleaning",
    Icon: FaMugHot,
    title: "Cafe Cleaning",
    images: [
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
    description:
      "We are looking for competent candidate for role of senior manager who can supervise our worker during field work. You can start asap.",
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
  },
  {
    slug: "retail-warehouse-cleaning",
    Icon: FaWarehouse,
    title: "Retail / Warehouse Cleaning",
    description: `As a cleaning company providing better and quality cleaning service around Australia, we also provide Retail cleaning services, what u sell like mobiles, laptops, clothes or other products your offices, stores or stalls should be clean which is fundamental in order to enhance your brand taking them to the higher reputation. Our expert in Green commercial cleaning company working passionately in retail premises like street stall, small or medium malls, large shopping center from a long time and they are happy with our services. Our far reaching scope of subordinate cleaning administrations, including window cleaning and floor and tile cleaning, loans itself impeccably to the retail cleaning prerequisites of shops who need to have their business sparkling all around consistently so as to stay at the highest point of their game and have the edge over their rivals.`,
    offerTitle: "",
    contentTitle: "",
    secondContentTitle: "",
    contentList: [],
    offerList: [],
    cover: images["retail_cleaning_cover.jpg"].default,
    images: [
      images["retail_cleaning.jpg"].default,
      images["service_img1.jpg"].default,
      images["service_img2.jpg"].default,
      images["service_img3.jpg"].default,
    ],
   
  },
];
