import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/authcontexts";

const WorkerPrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser?.claims?.role === "employee" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route>
  );
};

export default WorkerPrivateRoute;
