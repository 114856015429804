import React, { useState } from "react";
import "./EndOfLeaseSecondary.css";
import { Radio } from "antd";
import { ExtrasItems, CarpetSteamOptions } from "..";

const EndOfLeaseSecondary = () => {
  const [isCarpetSteamRequired, setIsCarpetSteamRequired] = useState(false);

  const onCarpetSteamRequired = (e) => {
    e.target.value === "yes"
      ? setIsCarpetSteamRequired(true)
      : setIsCarpetSteamRequired(false);
  };

  return (
    <div className="endOfLeaseSecondary">
      <div className="endOfLeaseSecondary__item">
        <p>Do you require Carpet steam Cleaning?</p>
        <Radio.Group
          onChange={onCarpetSteamRequired}
          className="endOfLeaseSecondary__options"
          name="radiogroup"
          defaultValue="no"
        >
          <Radio className="endOfLeaseSecondary__option" value="yes">
            Yes
          </Radio>
          <Radio className="endOfLeaseSecondary__option" value="no">
            No
          </Radio>
        </Radio.Group>
      </div>
      {isCarpetSteamRequired && <CarpetSteamOptions />}
      <div className="endOfLeaseSecondary__item">
        <p>Flea Treatment?</p>
        <Radio.Group
          className="endOfLeaseSecondary__options"
          name="radiogroup"
          defaultValue="no"
        >
          <Radio className="endOfLeaseSecondary__option" value="yes">
            Yes
          </Radio>
          <Radio className="endOfLeaseSecondary__option" value="no">
            No
          </Radio>
        </Radio.Group>
      </div>
      <div className="endOfLeaseSecondary__extras">
        <h2>EXTRAS</h2>
        <ExtrasItems />
      </div>
    </div>
  );
};

export default EndOfLeaseSecondary;
