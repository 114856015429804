import React from "react";
import LoginForm from "../../components/UI/LoginForm/LoginForm";
import "./Login.css";
import { Col, Row } from "antd";
import { Welcome } from "./../../components/UI/Welcome";
import loginImg from "./../../assets/login-image.png";

const Login = () => {
  return (
    <>
      <div className="login_container">
        <div className="login_content">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <LoginForm />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Welcome
                welcome_text={"Welcome Back"}
                button_text={""}
                account_text={""}
                link={"signup-client"}
                loginImg={loginImg}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Login;
