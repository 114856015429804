import React from "react";
import MessageEditor from "./../../MessageEditor/MessageEditor";
import DashboardHeader from "./../../DashboardHeader/DashboardHeader";

const RequestOtherServices = (props) => {
  return (
    <>
      <div className="request_service">
        <DashboardHeader
          header="Request Other Services"
          //   goBack={props.history.push}
          isNested
        />
        <MessageEditor  requestType="Request Other Service" />
      </div>
    </>
  );
};

export default RequestOtherServices;
