import React from "react";
import "./AvatarProf.css";
import { Avatar, Button } from "antd";
import avatar from "../../../../assets/avatar.png";

const AvatarProf = () => {
  return (
    <div className="avatarProf">
      <Avatar src={avatar} alt="avatar" size={160} />
      <Button className="bg-light">CHANGE AVATAR</Button>
    </div>
  );
};

export default AvatarProf;
