import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./HeaderButton.css";
import { Avatar, Menu, Dropdown, Button } from "antd";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  HomeFilled,
} from "@ant-design/icons";
import avatar from "../../../../assets/avatar.png";
import { useAuth } from "../../../../contexts/authcontexts";

const HeaderButton = () => {
  const { logout, currentUser } = useAuth();
  const history = useHistory();

  const handleLogoutClick = (e) => {
    logout().then(() => history.push("/"));
  };

  const userName = currentUser.claims.displayName;

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<HomeFilled />}>
        <Link to="/"> Home</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<HomeFilled />}>
        <Link
          to={
            currentUser.claims.role === "client"
              ? "/client/dashboard"
              : "/employee/dashboard"
          }
        >
          {" "}
          Dashboard
        </Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        <Link
          to={
            currentUser.claims.role === "client"
              ? "/client/dashboard/profile"
              : "/employee/dashboard/profile"
          }
        >
          {" "}
          Profile
        </Link>
      </Menu.Item>

      <Menu.Item onClick={handleLogoutClick} key="4" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="headerButton">
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button className="bg-dark">
          <Avatar src={avatar} alt="avatar" size="large" />
          {userName.length > 10 ? (
            <>{userName.substring(0, 10)}...</>
          ) : (
            userName
          )}
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default HeaderButton;
