import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Record.css";
import { Button, Modal } from "antd";
import { EyeOutlined, ArrowDownOutlined } from "@ant-design/icons";

const Record = ({ name, date, siteid }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    history.push({
      pathname: `/employee/dashboard/sites/upload/${siteid}`,
      state: { name, siteid },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="record">
      <div className="record__name">{name}</div>
      <div className="record__date">{date}</div>

      <div className="record__options">
        <Button className="bg-light" onClick={showModal}>
          Visit Site
        </Button>
        <Modal
          title={`${date}`}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button className="bg-light" key="back" onClick={handleCancel}>
              Punch Out
            </Button>,
            <Button className="bg-dark" key="submit" onClick={handleOk}>
              Punch In
            </Button>,
          ]}
        >
          <p>{name}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Record;
