import { Card, Col, Row } from "antd";

import React from "react";
import "./../Card.css";
import { Link } from "react-router-dom";
import { SERVICES } from "../../../../storage/services";

const ServiceCards = () => {
  return (
    <>
      <Row gutter={[0, 16]}>
        {SERVICES.map(({ Icon, title, cover, slug }) => (
          <Card
            hoverable
            style={{ width: "80%", background: "#000" }}
            className="service_card"
            cover={
              <img
                alt={title + " image"}
                src={cover ? cover : "https://picsum.photos/420/328"}
              />
            }
          >
            <p className="card_footer">
              <Link to={`/services/${slug}`} key={slug}>
                {title}
              </Link>
            </p>
          </Card>
        ))}
      </Row>
    </>
  );
};

export default ServiceCards;
