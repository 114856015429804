import React from "react";
import "./RequestAdditionalService.css";
import { DashboardHeader, MessageEditor } from "..";

function RequestAdditionalService(props) {
  return (
    <div className="requestAdditionalService">
      <DashboardHeader
        header="Request for Additional Services"
        goBack={props.history.push}
        isNested
      />
      <MessageEditor requestType="Request Additional Service" />
    </div>
  );
}

export default RequestAdditionalService;
