import React, { useState, useEffect } from "react";
import { DashboardHeader } from "../../Dashboard";
import "./Notifications.css";
import { useData } from "../../../../contexts/datacontext";
import NotificationBody from "../../Dashboard/Notifications/NotificationBody";

const WorkerNotifications = () => {
  const [visible, setVisible] = useState(8);
  const { notificationlist, Notification } = useData();

  useEffect(() => {
    Notification();
  }, []);

  const handleShowmore = () => {
    setVisible((prevState) => prevState + 4);
  };

  return (
    <>
      <div className="notifications">
        <DashboardHeader header="Notifications" />

        {notificationlist
          .slice(0, visible)
          .map(({ id, message, seen, type, url, docid }, index) => (
            <li key={index}>
              <NotificationBody
                id={id}
                message={message}
                seen={seen}
                type={type}
                url={url}
                docid={docid}
              />
            </li>
          ))}
      </div>
      <div className="notifications_showmore">
        <button
          className="notification_showmore_button"
          onClick={handleShowmore}
        >
          Show More
        </button>
      </div>
    </>
  );
};

export default WorkerNotifications;
