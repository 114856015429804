import React, { useState } from "react";
import "./UploadFile.css";
import { Form, Select, Upload, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import firebase, { db, storageRef } from "../../../../firebase";
import { useAuth } from "../../../../contexts/authcontexts";

const UploadFile = () => {
  const { Option } = Select;
  const { Dragger } = Upload;
  const { currentUser } = useAuth();

  const [docType, setDocType] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [disableUpload, setDisableUpload] = useState(true);
  const [upfileList, setupFileList] = useState([]);
  const [form] = Form.useForm();

  const onRemove = async (file) => {
    const index = upfileList.indexOf(file);
    const newFileList = upfileList.slice();
    newFileList.splice(index, 1);

    setupFileList(newFileList);
  };

  const onChange = ({ fileList }) => {
    setupFileList(fileList);
  };

  const documentTypeChange = (value) => {
    if (value) {
      setDocType(value);
      setDisableUpload(false);
    } else {
      setDisableUpload(true);
    }
  };

  const onFinish = async (values) => {
    try {
      setSubmitting(true);

      values.uploadedBy = currentUser.claims.user_id;
      values.uploadedAt = firebase.firestore.Timestamp.now();
      values.docType = docType;

      const dbFileList = await Promise.all(
        upfileList.map(async (file) => {
          try {
            const fileName = `uploads/documents/${
              values.uploadedBy
            }/${Date.now()}-${file.name}`;
            const fileRef = storageRef.child(fileName);
            const documentFile = await fileRef.put(file.originFileObj, {
              contentType: file.type,
            });
            const downloadUrl = await documentFile.ref.getDownloadURL();
            return { url: downloadUrl, path: fileName };
          } catch (e) {
            console.log(e);
          }
        })
      );

      values.documents = dbFileList;

      await db.collection("documents").add(values);
      form.resetFields();
      setupFileList([]);
      message.success("Document Uploaded Successfully");
    } catch (err) {
      message.error("Upload Error!! Please Try Again");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="uploadFile">
      <Form onFinish={onFinish} form={form}>
        <Select
          showSearch
          size="large"
          style={{ width: "100%", fontSize: "14px" }}
          placeholder="Type of Document"
          optionFilterProp="children"
          onChange={documentTypeChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="signedContract">Signed Contract</Option>
          <Option value="revisedContract">Revised Contract for Approval</Option>
          <Option value="otherDocuments">Other Documents</Option>
        </Select>
        <Form.Item
          name="documents"
          rules={[
            {
              required: true,
              message: "Upload Documents",
            },
          ]}
          style={{ textAlign: "center" }}
        >
          <div className="uploadFile__body">
            <Dragger
              className="uploadFile__icon"
              fileList={upfileList}
              onChange={onChange}
              onRemove={onRemove}
              maxCount={10}
              beforeUpload={() => false}
              disabled={disableUpload}
              multiple={true}
            >
              <PlusOutlined />
            </Dragger>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="uploadFile__btn">
            <Button className="bg-dark" shape="round" htmlType="submit">
              {submitting ? "Uploading..." : "Upload"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadFile;
