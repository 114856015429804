import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  useParams,
  useLocation,
} from "react-router-dom";
import { NavBar, Footer } from "./components/Layout";
import Routes from "./routes/routes";
import "./App.css";
import {
  UserDashboard,
  EmployeeDashboard,
  Login,
  SignUp,
  SignUpworker,
  ForgotPassword,
} from "./pages";
// import { useAuth } from "./contexts/authcontexts";
import ClientPrivateRoute from "./routes/clientPrivateRoute";
import WorkerPrivateRoute from "./routes/workerPrivateRoute";
// import { Service } from "./components/UI/Dashboard";

const App = () => {
  // const { currentUser } = useAuth();

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const pagesRoute = () => {
    return (
      <div className="App">
        <div className="app-body">
          <NavBar />
          <Routes />
          <Footer />
        </div>
      </div>
    );
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup-client" component={SignUp} />
        <Route exact path="/signup-worker" component={SignUpworker} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <ClientPrivateRoute
          exact
          path="/client/dashboard"
          component={withRouter(UserDashboard)}
        />
        <WorkerPrivateRoute
          exact
          path="/employee/dashboard"
          component={withRouter(EmployeeDashboard)}
        />
        <ClientPrivateRoute
          exact
          path="/client/dashboard/:tabs"
          component={withRouter(UserDashboard)}
        />
        <ClientPrivateRoute
          exact
          path="/client/dashboard/services/:slug"
          component={withRouter(UserDashboard)}
        />
        <WorkerPrivateRoute
          exact
          path="/employee/dashboard/:tabs"
          component={withRouter(EmployeeDashboard)}
        />
        <WorkerPrivateRoute
          exact
          path="/employee/dashboard/services/:slug"
          component={withRouter(EmployeeDashboard)}
        />
        <WorkerPrivateRoute
          exact
          path="/employee/dashboard/sites/upload/:slug"
          component={withRouter(EmployeeDashboard)}
        />
        <WorkerPrivateRoute
          exact
          path="/employee/dashboard/sites/upload"
          component={withRouter(EmployeeDashboard)}
        />
        <Route component={withRouter(pagesRoute)} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
