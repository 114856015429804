import React, { useState } from "react";
import "./ExtrasItem.css";
import { VscDiffAdded } from "react-icons/vsc";

const ExtrasItem = ({ icon, header }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div className="extrasItem">
      <span
        className="extrasItem__icon"
        onClick={() => setIsSelected(!isSelected)}
      >
        {!isSelected ? icon : <VscDiffAdded />}
      </span>
      <p className="extrasItem__header">{header}</p>
    </div>
  );
};

export default ExtrasItem;
