import React from "react";
import "./ContactUs.css";
import { DashboardHeader } from "..";

import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";

const ContactUs = () => {
  return (
    <div className="contactUs">
      <DashboardHeader header="Contact Us" />
      <div className="contactUs__body">
        <div className="contactUs__details">
          <div className="contactUs__detail">
            <FaPhoneAlt />
            <div className="contactUs__numbers">
              <p>0481 422 769 (24 hours)</p>
              <p>0385 485 721( Office hour)</p>
            </div>
          </div>
          <div className="contactUs__detail">
            <FaEnvelope />
            <p>info@greencommercialcleaning.com.au</p>
          </div>
          <div className="contactUs__detail">
            <GrLocation />
            <p>1021 Dandenong Road, Malvern East Vic 3145</p>
          </div>
        </div>
        <div className="contactUs__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50383.343500723306!2d145.02904321079018!3d-37.88464224380204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66839b2d3cc5b%3A0x8dfefe212b166b8!2sDandenong%20Rd%2C%20Malvern%20East%20VIC%203145%2C%20Australia!5e0!3m2!1sen!2snp!4v1626951814015!5m2!1sen!2snp"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            title="Green Commercial Cleaning"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
