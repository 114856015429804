import React from "react";
import "./Home.css";
import {
  Hero,
  Services,
  WhyChoose,
  TopClients,
  GetQoute,
} from "../../components/UI";

const header = (
  <span className="home__header">
    <span className="home__headerSpan">WHY</span> CHOOSE{" "}
    <span className="home__headerSpan">US</span>
  </span>
);

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <Services />
      <WhyChoose
        header={header}
        subtitle=" We strive to develop long standing relationships with our valued clients by ensuring the highest quality and most effective services are provided by our team of trained professional, with the assistance of our own proprietary web-based program."
      />
      <TopClients />
      <GetQoute />
    </div>
  );
};

export default Home;
