import React from "react";
import "./ForgotPassword.css";
import logo from "../../assets/green_commercial_logo 1.png";
import { FormLabel } from "../../components/UI/Dashboard/FormLabel";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ForgotPassword = () => {
  return (
    <div className="forgotPassword">
      <div className="forgotPassword__header">
        <img src={logo} alt="logo" />
      </div>
      <div className="forgotPassword__container">
        <div className="forgotPassword__body">
          <ExclamationCircleOutlined />
          <h2>Forgot Password?</h2>
          <p>
            Enter your email and we will send you a link to reset your password
          </p>
          <FormLabel label="Email Adress" name="email" />
          <Button
            className="bg-dark"
            style={{ width: "100%", height: "2.5rem" }}
          >
            SEND
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
