import React, { useState } from "react";
import "./MessageEditor.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { Select, Button, message, Spin } from "antd";
import { useAuth } from "../../../../contexts/authcontexts";
import firebase, { db } from "../../../../firebase";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

function MessageEditor({
  dropOptions,
  selectMessage,
  placeholder,
  requestType,
}) {
  const { currentUser } = useAuth();
  const { Option } = Select;
  const [selectValue, setselectValue] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  console.log("currentUser claims", currentUser.claims);

  function onChange(value) {
    setselectValue(value);
  }

  console.log(selectValue);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const editorValue = editorState.getCurrentContent().getPlainText();
  console.log(editorValue);

  const handleSubmitRequest = (e) => {
    setIsSubmitted(true);
    let date = firebase.firestore.Timestamp.now();
    let requests = {
      userId: currentUser.claims.user_id,
      uploadedAt: date,
      requestType: requestType,
      optionType: selectValue ? selectValue : null,
      requestMessage: editorValue,
    };

    db.collection("Requests")
      .doc()
      .set(requests)
      .then(() => {
        let optionType = selectValue ? selectValue : null;
        let msg = "Option Selected: " + optionType + ":" + editorValue;
        let mailapidata = {
          name: currentUser.claims.displayName,
          requestfor: requestType,
          message: msg,
        };
        console.log("mailapidata", mailapidata);
        mailapidata = JSON.stringify(mailapidata);
        try {
          // Email Notification
          axios
            .post(
              "https://gcc-mail-support.herokuapp.com/api/request",
              mailapidata
            )
            .then(() => {
              setIsSubmitted(false);
              setEditorState(() => EditorState.createEmpty());
              message.success("Support Notified Successfully.");
            });
        } catch (e) {
          setIsSubmitted(false);
          message.error("Support Notification Error!!");
        }
        // message.success("Request Submitted Successfully.");
        console.log("successfull");
      })
      .catch((err) => {
        message.error(err);
        setIsSubmitted(false);
      });
  };

  return (
    <div className="messageEditor">
      {selectMessage ? (
        <p style={{ marginBottom: "20px !important" }}>{selectMessage}</p>
      ) : null}

      <form onSubmit={handleSubmitRequest}>
        {dropOptions ? (
          <Select
            showSearch
            size="large"
            style={{ width: "100%", fontSize: "14px" }}
            placeholder={placeholder ? placeholder : "Type of Document"}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dropOptions.map((dropOption, index) => (
              <Option key={index} value={dropOption}>
                {dropOption}
              </Option>
            ))}
          </Select>
        ) : (
          <p>
            Please write about the services you want to request and we will get
            back to you.
          </p>
        )}

        <div className="messageEditor__editor">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            placeholder="Text here.."
          />
        </div>
        <div className="messageEditor__btns">
          <Button className="bg-light">CANCEL</Button>
          <Button
            className="bg-dark"
            type="submit"
            onClick={handleSubmitRequest}
          >
            {isSubmitted ? <Spin style={{ color: "white" }} /> : null} SEND
          </Button>
        </div>
      </form>
    </div>
  );
}

export default MessageEditor;
