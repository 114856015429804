import React from "react";
import { Document } from "../../Dashboard";
import { DashboardHeader } from "../../Dashboard";

const Invoices = (props) => {
  const datas = [
    // { name: "June 2021", date: "30-June-2021" },
    // { name: "May 2021", date: "30-May-2021" },
  ];

  return (
    <div className="invoices">
      <DashboardHeader
        header="My invoices"
        goBack={props.history.push}
        isNested
      />
      <Document header="Invoice Period" datas={datas} />
    </div>
  );
};

export default Invoices;
