import React, { useState } from "react";
import "./SignUpFormClient.css";
import { Button, Form, Input, Select, message, Spin } from "antd";
import { Link, useHistory } from "react-router-dom";
import logo from "./../../../assets/green_commercial_logo 1.png";
import Checkbox from "antd/lib/checkbox/Checkbox";
import axios from "axios";
import { clientRegister } from "../../../utils/api";
import { GiTrumpet } from "react-icons/gi";

const { Option } = Select;

const SignUpFormClient = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const [isRegistering, setisRegistering] = useState(false);

  const onFinish = (values) => {
    setisRegistering(GiTrumpet);
    // Format data
    let phone = values.prefix + values.contact;

    let data = {
      name: values.name,
      email: values.email,
      password: values.password,
      contact: phone,
      businessName: values.businessName,
      businessAddress: values.businessAddress,
      businessType: values.businessType,
      australianBusinessNumber: values.australianBusinessNumber,
    };

    let apiData = JSON.stringify(data);
    console.log("sent data:", apiData);

    // Register account with api
    axios({
      method: "post",
      url: clientRegister,
      data: apiData,
      headers: { "Content-Type": "application/json" },
    })
      .then(() => {
        message.info("User Registered successfully.");
        // reset form fields
        form.resetFields();
        setisRegistering(false);
        // Redirect to LogIn page
        history.push("/login");
      })
      .catch((error) => {
        console.log("error message:", error.response.data.message.message);
        message.info(error.response.data.message.message);
        setisRegistering(false);
      });
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },

      lg: {
        span: 24,
        offset: 0,
      },
    },
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="977">+977</Option>
        <Option value="86">+86</Option>
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        prefix: "977",
      }}
      scrollToFirstError
    >
      <Link to="/">
        <img src={logo} alt="Green Commercial Cleaning" className="logo" />
      </Link>
      <h2>Register Form</h2>
      <Form.Item
        name="name"
        label="Fullname"
        tooltip="What do you want others to call you?"
        rules={[
          {
            required: true,
            message: "Please input your username!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Phone Number"
        rules={[{ required: true, message: "Please input your phone number!" }]}
      >
        <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="businessName"
        label="Businessname"
        tooltip="Please provide your business name?"
        rules={[
          {
            required: true,
            message: "Please input your businessname!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="businessAddress"
        label="Business address"
        tooltip="Please provide your business address?"
        rules={[
          {
            required: true,
            message: "Please input your business address!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="businessType"
        label="Business Type"
        tooltip="Please Select your Business Type?"
        rules={[
          {
            required: true,
            message: "Please Select your Business Type!",
          },
        ]}
      >
        <Select>
          <Option value="BuilderAndConstruction">
            Builder and Construction
          </Option>
          <Option value="Office">Office</Option>
          <Option value="MedicalCentre">Medical Centre</Option>
          <Option value="FitnessClub">Fitness Club</Option>
          <Option value="BusinessComplex">Business Complex</Option>
          <Option value="Warehouse">Warehouse</Option>
          <Option value="Corporate">Strata / Body Corporate</Option>
          <Option value="Retail">Retail</Option>
          <Option value="CafeAndRestaurant">Cafe and Restaurant</Option>
          <Option value="EducationalIntitution">Educational Institution</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="australianBusinessNumber"
        label="ABN"
        rules={[{ required: true, message: "Please input your ABN number!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Should accept agreement")),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          By clicking register, you agree to our{" "}
          <Link href="">TERMS AND CONDITION</Link>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="button"
          value="submit"
          htmlType="submit"
          className="login-form-button bg-dark"
        >
          {isRegistering ? <Spin style={{ color: "white" }} /> : null}
          Register
        </Button>
        <br />
        {/* Already member? <Link to="/login">Login</Link> */}
      </Form.Item>
    </Form>
  );
};

export default SignUpFormClient;
