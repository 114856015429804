import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Select, Button } from "antd";
import GenerateQuoteImg from "../../../assets/generate-quote.png";
import { SERVICES } from "../../../storage/services";
import "./GenerateQuoteForm.css";

const { Option } = Select;

const GenerateQuoteForm = () => {
  const [isEmpty, setIsEmpty] = useState(true);

  const history = useHistory();

  const handleNext = (values) => {
    const service = SERVICES.filter(
      ({ title }) => title === values.typeOfCleaning
    );
    history.push(`/services/${service[0].slug}/getqoute`);
  };

  return (
    <div className="generate-quote-container">
      <div className="generate-quote-image-container">
        <img src={GenerateQuoteImg} alt="generate quote" />
      </div>
      <div className="description">
        <p>Please specify the type of cleaning you want.</p>
      </div>
      <div className="generate-quote-form-container">
        <Form className="generate-quote-form" onFinish={handleNext}>
          <Form.Item
            name="typeOfCleaning"
            rules={[
              { required: true, message: "Please select type of cleaning" },
            ]}
          >
            <Select
              placeholder="Type of Cleaning"
              onChange={() => setIsEmpty(false)}
            >
              {SERVICES.map((service) => (
                <Option value={service.title} key={service.slug}>
                  {service.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="get-quote-button-container">
            <Button
              disabled={isEmpty}
              className="get-quote-button bg-dark"
              htmlType="submit"
            >
              NEXT
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default GenerateQuoteForm;
