import React from 'react';
import "./RequestDashboardLanding.css";
import { DashboardHeader,  } from "..";
import DashboardRequestItems from './../DashboardRequestItems/DashboardRequestItems';

const RequestDashboardLanding = () => {
  const REQUESTS = [
    { header: "Request supervision", slug: "request-supervision" },
    { header: "Request One Off services", slug: "request-one-off-services" },
    { header: "Request Regular Services", slug: "request-regular-services" },
    { header: "Request Other Services", slug: "request-others-services" },
  ];

  return (
    <div className="dashboardLanding">
      <DashboardHeader header="DASHBOARD" />
      <div className="dashboardLanding__body">
        
        <ul>
          {REQUESTS.map(({ header, slug }, index) => (
            <DashboardRequestItems key={index} header={header} slug={`${slug}`} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RequestDashboardLanding
