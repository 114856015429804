import { Collapse } from "antd";
import React from "react";
import { EndOfLeaseAccordionData } from "./EndOfLeaseAccordionData";

const { Panel } = Collapse;

const EndOfLeaseAccordion = () => {
  return (
    <div className="endOfLeaseAccordion">
      <Collapse bordered={true}>
        {EndOfLeaseAccordionData.map((data) => (
          <Panel header={data.title} key={data.key}>
            {data.content}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default EndOfLeaseAccordion;
