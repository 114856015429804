import React from "react";
import "./BookingSummary.css";

const BookingSummary = () => {
  return (
    <div className="bookingSummary">
      <p>Your Booking Summary</p>
      <p className="bookingSummary__caption">
        <span>Clean Type:</span>
        <span>Fixed Packages End Of Tenacy</span>
      </p>
      <div className="bookingSummary__total">
        <p>YOUR TOTAL</p>
        <span>$0.00</span>
      </div>
    </div>
  );
};

export default BookingSummary;
