import React, { useState } from "react";
import { message } from "antd";
// import "./SignUpFormClient.css";
import { Button, Form, Input, Select, Spin } from "antd";
import { Link, useHistory } from "react-router-dom";
import logo from "./../../../assets/green_commercial_logo 1.png";
import Checkbox from "antd/lib/checkbox/Checkbox";
// import "./data.json";
import axios from "axios";
import { workerRegister } from "../../../utils/api";
import { db } from "../../../firebase";

const SignUpFormWorker = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const { Option } = Select;
  const [isRegistering, setisRegistering] = useState(false);

  // check for the admin registered client email list => 'Client Email'
  const checkEmail = async (tocheckemail) => {
    return new Promise(async (resolve, reject) => {
      console.log("inside check email promise", tocheckemail);
      db.collection("Staff Email")
        .where("email", "==", tocheckemail)
        .get()
        .then((querysnapshot) => {
          resolve(querysnapshot.size);
        });
    });
  };

  const onFinish = async (values) => {
    setisRegistering(true);

    let email = await checkEmail(values.email);

    if (email !== 0) {
      // Format data
      let phone = values.prefix + values.contact;

      let data = {
        name: values.name,
        email: values.email,
        password: values.password,
        contact: phone,
      };

      let apiData = JSON.stringify(data);
      console.log("sent data:", apiData);
      console.log("api endpoint", workerRegister);

      // Register account with api
      try {
        axios({
          method: "post",
          url: workerRegister,
          data: apiData,
          headers: { "Content-Type": "application/json" },
        })
          .then(() => {
            message.info("User Registered successfully.");
            // reset form fields
            form.resetFields();
            setisRegistering(false);
            // Redirect to LogIn page
            history.push("/login");
          })
          .catch((error) => {
            console.log("error message:", error.response.data.message.message);
            message.info(error.response.data.message.message);
            setisRegistering(false);
          });
      } catch (err) {
        message.error(err);
        setisRegistering(false);
      }
    } else {
      setisRegistering(false);
      message.info("Your Email is not registered by Admin to the System.");
      message.info("Please Contact Admin for Registration.");
    }
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },

      lg: {
        span: 24,
        offset: 0,
      },
    },
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="+61">+61</Option>
        <Option value="+977">+977</Option>
        <Option value="+86">+86</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );

  // const onSubmit = (data) => {
  //   alert(JSON.stringify(data));
  // }

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        prefix: "+61",
      }}
      scrollToFirstError
    >
      <Link to="/">
        <img src={logo} alt="Green Commercial Cleaning" className="logo" />
      </Link>

      <h2>Register Form</h2>
      <Form.Item
        name="name"
        label="Fullname"
        tooltip="What do you want others to call you?"
        rules={[
          {
            required: true,
            message: "Please input your username!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Phone Number"
        rules={[{ required: true, message: "Please input your phone number!" }]}
      >
        <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Should accept agreement")),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          By clicking register, you agree to our{" "}
          <Link href="">TERMS AND CONDITION</Link>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="button"
          value="submit"
          htmlType="submit"
          className="login-form-button bg-dark"
        >
          {isRegistering ? <Spin style={{ color: "white" }} /> : null}
          Register
        </Button>
        <br />
        {/* Already member? <Link to="/login">Login</Link> */}
      </Form.Item>
    </Form>
  );
};

export default SignUpFormWorker;
