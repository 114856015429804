import React, { useState } from "react";
import { Row, Col, Button, Modal, Tabs } from "antd";
import { GetQuoteEmailForm, GenerateQuoteForm } from "../../UI";
import { QouteData } from "./QouteData";
import "./Qoute.css";

const { TabPane } = Tabs;

const GetQoute = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="qoute_container">
        <h3 className="heading_style">
          HOW TO <span>PROCEED</span>
        </h3>

        <div className="content" style={{ marginTop: "20px" }}>
          <Row gutter={[16, 16]}>
            {QouteData.map(item => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={6}
                className="qoute_col"
                key={item.title}
              >
                <div style={{ color: "#0F8015", fontSize: "3rem" }}>
                  {item.icon}
                </div>
                <p className="qoute_header">{item.title}</p>
                <p className="qoute_details">{item.details}</p>
              </Col>
            ))}
          </Row>

          <div className="button_container">
            <p>
              Generate online instant quote using our unique quote generator
              tool
            </p>
            <div style={{ marginTop: "20px" }}>
              <Button className="bg-dark" onClick={showModal}>
                GENERATE QUOTE
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={false}
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        style={{ top: 20 }}
      >
        <Tabs defaultActiveKey="1" centered className="services-quote">
          <TabPane tab="GET QUOTE USING EMAIL ADDRESS" key="1">
            <GetQuoteEmailForm />
          </TabPane>
          <TabPane tab="USE OUR QUOTE ESTIMATOR TOOL" key="2">
            <GenerateQuoteForm />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default GetQoute;
