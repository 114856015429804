import React from "react";
import "./ExtrasItems.css";
import { ExtrasItem } from "..";
import {
  GiHomeGarage,
  GiBrickWall,
  GiFamilyHouse,
  GiBarbecue,
  GiFireplace,
  GiConsoleController,
  GiSpiderWeb,
  GiSofa,
} from "react-icons/gi";
import { BiFridge, BiBuildingHouse } from "react-icons/bi";
import { FaToilet, FaUmbrellaBeach, FaChair } from "react-icons/fa";

const ExtrasItems = () => {
  const EXTRAS = [
    {
      Icon: GiBrickWall,
      title: "Wall Wash",
    },
    {
      Icon: FaChair,
      title: "Furnished Property",
    },
    {
      Icon: GiHomeGarage,
      title: "Single Garage",
    },
    {
      Icon: BiFridge,
      title: "Single Garage",
    },
    {
      Icon: GiFamilyHouse,
      title: "Double Storey",
    },
    {
      Icon: FaToilet,
      title: "Extra Toilet/Powder Room",
    },
    {
      Icon: BiBuildingHouse,
      title: "Triple Storey",
    },
    {
      Icon: GiBarbecue,
      title: "Barbecue",
    },
    {
      Icon: FaUmbrellaBeach,
      title: "Deck/Courtyard",
    },
    {
      Icon: GiFireplace,
      title: "Fireplace",
    },
    {
      Icon: GiConsoleController,
      title: "Rumpus Room",
    },
    {
      Icon: GiSpiderWeb,
      title: "External Cobweb Removal",
    },
    {
      Icon: GiSofa,
      title: "Extra Living Area",
    },
  ];

  return (
    <div className="extrasItems">
      {EXTRAS.map(({ Icon, title }, index) => (
        <ExtrasItem key={index} icon={<Icon />} header={title} />
      ))}
    </div>
  );
};

export default ExtrasItems;
