import React, { useState } from "react";
import "./Hero.css";
import { FaPhoneAlt } from "react-icons/all";

import { Row, Col, Button, Modal, Tabs } from "antd";
import { GetQuoteEmailForm, GenerateQuoteForm } from "../";

const Hero = () => {
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="hero">
        <div className="hero__container">
          <div className="hero__header">
            <h1>
              <span>PROFESSIONAL CLEANING SERVICE</span> YOU CAN TRUST
            </h1>
          </div>
          <div className="hero__body">
            <p>
              GCC is a proudly Australian owned company that provides
              comprehensive cleaning services, efficiently and effectively to
              Commercial, Industrial, Retail and Public Facilities. We strive to
              develop long standing relationships with our valued clients by
              ensuring the highest standards and most effective services. GCC
              recommends our client to prioritise environment friendly chemicals
              as our company is focused on environment friendly solutions.
            </p>
          </div>
          <div className="hero__bottom">
            <Button className="hero__btnRequest bg-dark" onClick={showModal}>
              REQUEST A QUOTE
            </Button>
            <Button className="bg-light">
              {/* <div className="hero__btnCall"> */}
              <a href="tel:1300400227" className="hero__btnCall">
                <div className="hero__btnIcon">
                  <FaPhoneAlt />
                </div>
                <div className="hero__btnInfo">
                  <p>0481 422 769</p>
                  <p>
                    We are on call <span>24x7</span>
                  </p>
                </div>
              </a>
              {/* </div> */}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title={false}
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        style={{ top: 20 }}
      >
        <Tabs defaultActiveKey="1" centered className="services-quote">
          <TabPane tab="GET QUOTE USING EMAIL ADDRESS" key="1">
            <GetQuoteEmailForm />
          </TabPane>
          <TabPane tab="USE OUR QUOTE ESTIMATOR TOOL" key="2">
            <GenerateQuoteForm />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Hero;
