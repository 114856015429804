import React,{useEffect} from "react";

import { DashboardHeader } from "../../Dashboard";
import { useData } from "../../../../contexts/datacontext";
import DocumentReport from "../DocumentReport/DocumentReport"
import "./Reports.css"

const Reports = (props) => {
 const {RecordList,recordlist} = useData();

 useEffect(() => { 
  RecordList();
}, []);

  return (
    <div className="reports">
      <DashboardHeader
        header="My Reports"
        goBack={props.history.push}
        isNested
      />
        <DocumentReport header="Report Name" datas={recordlist} />
    </div>
  );
};

export default Reports;
