import React, { useState } from "react";
import "./Dashboard.css";
import { Switch, Route } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { AiOutlineMenu } from "react-icons/all";
import {
  RequestOtherServices,
  RequestRegularServices,
  RequestOneOffServices,
  DashboardLanding,
  RequestDashboardLanding,
  RequestSupervision,
  Notifications,
  TermsAndConditions,
  OurServices,
  ContactUs,
  OnboardingHelp,
  HeaderButton,
  Service,
  Reports,
  Invoices,
  Documents,
  FeedbackAndReviews,
  ComplaintSupport,
  RequestAdditionalService,
  UploadDocument,
  Profile,
} from ".";

const Dashboard = () => {
  const [isMobMenuVisible, setIsMobMenuVisible] = useState(false);

  const showMobMenu = () => setIsMobMenuVisible(!isMobMenuVisible);

  return (
    <div className="dashboard">
      {/* <BrowserRouter> */}
      <div className="dashboard__sidebar">
        <Sidebar
          isMobMenuVisible={isMobMenuVisible}
          showMobMenu={showMobMenu}
        />
      </div>
      <div className="dashboard__content">
        <div className="dashboard__header">
          <span className="dashboard__mobMenuOpen">
            <AiOutlineMenu onClick={showMobMenu} />
          </span>
          <HeaderButton />
        </div>
        <Switch>
          <Route path="/client/dashboard/" component={DashboardLanding} exact />
          <Route path="/client/dashboard/reports" component={Reports} />
          <Route path="/client/dashboard/invoices" component={Invoices} />
          <Route path="/client/dashboard/documents" component={Documents} />
          <Route
            path="/client/dashboard/feedback-and-reviews"
            component={FeedbackAndReviews}
          />
          <Route
            path="/client/dashboard/support"
            component={ComplaintSupport}
          />
          <Route
            path="/client/dashboard/request"
            component={RequestAdditionalService}
          />
          <Route
            path="/client/dashboard/request-other-services"
            component={RequestDashboardLanding}
          />
          <Route
            path="/client/dashboard/request-supervision"
            component={RequestSupervision}
          />
          <Route
            path="/client/dashboard/request-one-off-services"
            component={RequestOneOffServices}
          />

          <Route
            path="/client/dashboard/request-regular-services"
            component={RequestRegularServices}
          />
          <Route
            path="/client/dashboard/request-others-services"
            component={RequestOtherServices}
          />

          <Route path="/client/dashboard/upload" component={UploadDocument} />
          <Route path="/client/dashboard/profile" component={Profile} />
          <Route
            path="/client/dashboard/notifications"
            component={Notifications}
          />
          <Route
            path="/client/dashboard/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route
            path="/client/dashboard/services"
            component={OurServices}
            exact
          />
          <Route path="/client/dashboard/services/:slug" component={Service} />
          <Route path="/client/dashboard/contact-us" component={ContactUs} />
          <Route path="/client/dashboard/help" component={OnboardingHelp} />
        </Switch>
      </div>
      {/* </BrowserRouter> */}
    </div>
  );
};

export default Dashboard;
