import React from "react";
import "./OnboardingHelp.css";
import { DashboardHeader } from "..";

const OnboardingHelp = () => {
  return (
    <div className="onboardingHelp">
      <DashboardHeader header="Onboarding Help" />
    </div>
  );
};

export default OnboardingHelp;
