import React from "react";
import "./WhyChoose.css";
import ellipse from "../../../assets/why-us.png";

const WhyChoose = ({ isBgWhite, header, subtitle }) => {
  return (
    <div className={isBgWhite ? "whyChoose whyChoose-isWhite" : "whyChoose"}>
      <div className="content">
        <div className="whyChoose__header">
          <h3>{header}</h3>
        </div>
        <div className="whyChoose__subtitle">
          <p>{subtitle}</p>
        </div>
        <div className="whyChoose__body">
          <div className="whyChoose__image">
            <img src={ellipse} alt="ellipse" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
