import React from "react";
import "./CovidCleaner.css";
import Viraclean from "../../../assets/covid-viraclean.png";

const CovidCleaner = () => {
  return (
    <div className="covidCleaner">
      <div className="covidCleaner__container">
        <div className="covidCleaner__header">
          <h2>CHEMICAL USED IN DISINFECTION SERVICE</h2>
        </div>
        <div className="covidCleaner__description">
          <p>
            We use VIRACLEAN to disinfect your surrounding areas to reduce the
            contamination of virus. VIRACLEAN is a one-step disinfectant cleaner
            that is effective against a broad spectrum of bacteria, viruses, and
            inhibits the growth of mold/mildew and their odors. VIRACLEAN is
            proved that it does not contain dangerous chemical according to GHS
            or HSNO criteria.{" "}
          </p>
          <p>
            You can use Viraclean on floors, desks and high-touch areas, such as
            door handles, railings, workstations and telephones.
          </p>
        </div>
        <div className="covidCleaner__header">
          <h2>VIRACLEAN IS PROVEN TO KILL DIFFERENT VIRUSES</h2>
        </div>
        <div className="covidCleaner__description">
          <p>VIRACLEAN has been proven to kill following virues:</p>
          <ul>
            <li>Acinetobacter</li>
            <li>Candida albicans</li>
            <li>Coronaviruses including SARS-CoV-2 (COVID-19)</li>
            <li>Enterococcus faecalis (VRE)</li>
            <li>scherichia coli (E coli)</li>
            <li>Hepatitis B Group virus</li>
            <li>Herpes Simplex virus</li>
            <li>Influenza virus</li>
            <li>Klebsiella pneumoniae (CPE/CRE)</li>
            <li>Proteus vulgaris</li>
            <li>Pseudomonas aeruginosa</li>
            <li>Salmonella choleraesuis</li>
            <li>Staphylococcus aureus (MRSA or Golden Staph)</li>
          </ul>
        </div>
        <p>(Source: www.whiteley.com.au)</p>
      </div>
      <div className="covidCleaner__image">
        <img src={Viraclean} alt="" />
      </div>
    </div>
  );
};

export default CovidCleaner;
