import React from "react";
import "./DashboardLanding.css";
import { DashboardHeader, DashServicesItem } from "..";

const DashboardLanding = () => {
  const ITEMS = [
    { header: "My Reports", slug: "reports" },
    { header: "My Invoices", slug: "invoices" },
    { header: "My Documents", slug: "documents" },
    { header: "Feedback & Review", slug: "feedback-and-reviews" },
    { header: "Complaint Manager & Support", slug: "support" },
    { header: "Request For Additional Service", slug: "request" },
    { header: "Upload Documents", slug: "upload" },
    { header: "Request Other Services", slug: "request-other-services" },
  ];

  return (
    <div className="dashboardLanding">
      <DashboardHeader header="DASHBOARD" />
      <div className="dashboardLanding__body">
        <ul>
          {ITEMS.map(({ header, slug }, index) => (
            <DashServicesItem key={index} header={header} slug={`${slug}`} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardLanding;
