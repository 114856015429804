import React, { useState } from "react";
import "./Profile.css";
import { DashboardHeader, AvatarProf, FormLabel } from "..";
import { Button, Form } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/authcontexts";

const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);

  const { currentUser } = useAuth();

  const LABELS = [
    { label: "Full Name", name: "fullName" },
    { label: "Phone", name: "phone" },
    { label: "Email Address", name: "email" },
    { label: "Password", name: "password", isPassword: true },
  ];

  const EXTRAS = [
    { label: "Business Name", name: "businessName" },
    { label: "Business Address", name: "businessAddress" },
  ];

  currentUser.claims.role === "client" && LABELS.push(...EXTRAS);

  const handleEditSave = () => {
    setIsEdit(!isEdit);
  };

  return (
    <div className="profile">
      <DashboardHeader header="My Profile" />
      <div className="profile__container">
        <div className="profile__avatar">
          <AvatarProf />
        </div>
        <div className="profile__userForm">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
          >
            {LABELS.map(({ label, name, isPassword }, index) => (
              <FormLabel
                key={index}
                label={label}
                name={name}
                isPassword={isPassword}
              />
            ))}

            <div className="profile__handles">
              <Link to="/">Change Password</Link>
              <div className="profile__btns">
                {isEdit && (
                  <Button className="bg-light" onClick={() => setIsEdit(false)}>
                    CANCEL
                  </Button>
                )}
                <Button className="bg-dark" onClick={handleEditSave}>
                  {!isEdit ? "EDIT DETAILS" : "SAVE CHANGES"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
