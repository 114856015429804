import React from "react";
import OurMissionImg from "../../../assets/about-us-our-mission.png";
import OurVisionImg from "../../../assets/about-us-our-vision.png";
import OurValuesImg from "../../../assets/about-us-our-values.png";
import "./AboutUsCards.css";

const AboutUsCards = () => {
  return (
    <div className="about-us-cards-container">
      <div className="header">
        <h3>
          ABOUT <span>GREEN</span>
        </h3>
      </div>
      <div className="about-us-cards-group">
        <div className="about-us-card">
          <img src={OurMissionImg} alt="Our Mission" />
          <h5>
            OUR <span>MISSION</span>
          </h5>
          <p className="about-us-card-description">
            GCC is committed to providing the highest quality commercial
            cleaning service available by exceeding the expectations of our
            clients, as well as their employees, patients, students, and other
            visitors to their premises or facilities.
          </p>
        </div>
        <div className="about-us-card">
          <img src={OurVisionImg} alt="Our Vision" />
          <h5>
            OUR <span>VISION</span>
          </h5>
          <p className="about-us-card-description">
            We are constantly working to establish ourselves as the most
            respected and pursued contract cleaning and facilities support
            service in Australia. We seek to build a partnership philosophy
            based on solid and trusting relationships with our key stakeholders.
          </p>
        </div>
        <div className="about-us-card">
          <img src={OurValuesImg} alt="Our Values" />
          <h5>
            OUR <span>VALUES</span>
          </h5>
          <p className="about-us-card-description">
            GCC stands for far more than just cleaning. We’re about developing
            relationships and partnerships with our clients and working with
            them to find solutions to their problems.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsCards;
