import React from "react";
import "./DashboardRequestItems.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/authcontexts";

const DashboardRequestItems = ({ header, slug }) => {
  const { currentUser } = useAuth();

  return (
    <>
      <Link to={`/${currentUser.claims.role}/dashboard/${slug}`}>
        <li className="dashServicesItem">{header}</li>
      </Link>
    </>
  );
};

export default DashboardRequestItems;
