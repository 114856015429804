import React from "react";
import "./AboutUs.css";
import {
  AboutUsIntro,
  AboutUsCards,
  GetQoute,
  WhyChoose,
} from "../../components/UI";

const AboutUs = () => {
  const header = (
    <span className="aboutUs__header">
      WHY CHOOSE <span className="aboutUs__headerSpan">GREEN SERVICE</span>
    </span>
  );

  return (
    <div className="about-us-container">
      <AboutUsIntro />
      <AboutUsCards />
      <WhyChoose
        isBgWhite
        header={header}
        subtitle="We strive to develop long standing relationships with our valued clients by ensuring the highest quality and most effective services are provided by our team of trained professional, with the assistance of our own proprietary web-based program."
      />
      <GetQoute />
    </div>
  );
};

export default AboutUs;
