import React from "react";
import { Route, withRouter } from "react-router-dom";
import {
  Home,
  Careers,
  Services,
  AboutUs,
  Contact,
  ServiceDetail,
  Covid,
  Quote,
} from "../pages";

const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/careers",
    component: Careers,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/services",
    component: Services,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/services/:slug",
    component: ServiceDetail,
  },
  {
    path: "/covid-19",
    component: Covid,
  },
  {
    path: "/services/:serviceslug/getqoute",
    component: Quote,
  },
];

const Routes = () => {
  return (
    <>
      {ROUTES.map((route, index) => (
        <Route
          key={index}
          exact
          path={route.path}
          component={route.component}
        />
      ))}
    </>
  );
};

export default withRouter(Routes);
