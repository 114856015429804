import React from "react";
import "./ViewModel.css";
import ViewData from "./ViewData"
const ViewModal = ({ header="Document Name", datas}) => {
  // console.log(datas,"data of the data")
  return (
    <div className="viewmodel">
      <div className="viewmodel__body">
        <div className="viewmodel__bodyHead">
          <p>{header}</p>
          {/* <p>{subHeader}</p> */}
          <p>Options</p>
        </div>

      <div>
       <ul>
          {datas.map(({ url,path}, index) => (
            <li key={index}>
            <ViewData name={path} url={url} />
            </li>

          
          ))}
        </ul>
      </div>
       
      </div> 
    </div>
  );
};

export default ViewModal;
