import React from "react";
import MessageEditor from "./../../MessageEditor/MessageEditor";
import DashboardHeader from "./../../DashboardHeader/DashboardHeader";
// import { useAuth } from "./../../../../../contexts/authcontexts";
// import firebase, { db } from "./../../../../../firebase";
// import { message } from "antd";

const RequestOneOffServices = (props) => {
  // const { currentUser } = useAuth();
  const dropOptions = [
    "Carpet Cleaning",
    "Windows CLeaning",
    "Covid disinfection and Sanitization",
    "Pressure wash",
    "Car park Cleaning",
    "Flood and leakage management",
    "Waste Management",
    "Floor Treatment",
    "Other Services(Custom)",
    "Toilet Requisites Management",
  ];
 

  return (
    <>
      <div className="request_service">
        <DashboardHeader
          header="Request One Off Services"
          goBack={props.history.push}
          isNested
        />
        <MessageEditor
          dropOptions={dropOptions}
          selectMessage="Select one of the services"
          placeholder="Choose one off services"
          // parentCallback={onChange}
          // handleSubmitRequest={handleSubmitRequest}
          requestType="Request One Off Service"
        />
      </div>
    </>
  );
};

export default RequestOneOffServices;
