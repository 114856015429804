import React from "react";
import "./Service.css";
import { DashboardHeader } from "..";
import { SERVICES } from "../../../../storage/services";

const Service = (props) => {
  console.log("inside service");
  const service = SERVICES.filter(
    (service) => service.slug === props.match.params.slug
  );

  return (
    <div className="service">
      <DashboardHeader
        header={service[0].title}
        goBack={props.history.push}
        isService
        isNested
      />
      <div className="service__body">
        <p>{service[0].description}</p>
        {service[0].contentTitle && <p>{service[0].contentTitle}</p>}
        <ul>
          {service[0].contentList?.map((list, index) => (
            <li key={index}>{list}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Service;
