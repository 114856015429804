import React from "react";
import "./Document.css";
import { Link } from "react-router-dom";
import { Record } from "..";
import { InfoCircleFilled } from "@ant-design/icons";
import { useAuth } from "../../../../contexts/authcontexts";

const Siteslist = ({ header, subHeader = "Date Generated", datas }) => {
  const { currentUser } = useAuth();
  return (
    <div className="document">
      <div className="document__body">
        <div className="document__bodyHead">
          <p>{header}</p>
          <p>{subHeader}</p>
          <p>Options</p>
        </div>

        <ul>
          {datas.map(({ sitename, id }, index) => (
            <li key={index}>
              <Record name={sitename} siteid={id} date="-" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Siteslist;
