import React, { useState} from "react";
import "./ViewData.css";
import { Button, Modal } from "antd";
import { EyeOutlined, ArrowDownOutlined } from "@ant-design/icons";


const Record = ({ name,url}) => {

  const [visible, setVisible] = useState(false);
  const handleClick= async()=>{
   setVisible(true) 
  }
 var newname = name.split("uploads/documents/");


  return (
    <div className="viewdata">
      <div className="viewdata__name">{newname}</div>

      <div className="viewdata__options">
       
          <>
            <div className="viewdata__btn">
              <Button className="bg-light" 
         onClick={handleClick}
              >
                <EyeOutlined />
              </Button>
            </div>
            <div className="viewdata__btn">
              <Button className="bg-dark">
                <ArrowDownOutlined />
              </Button>
            </div>
          </>
      
      </div>
      <Modal
        title={newname}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
    <img src={url} alt="Image" width="500" height="400"></img>
    <ul>

    </ul>

      </Modal> 
    
    </div>
  );
};

export default Record;
