import React from "react";
import { PageHeader } from "../../components/Layout";
import { EndOfLease } from "../../components/UI";
import PageHeaderImg from "../../assets/careers-page-header.png";
import { SERVICES } from "../../storage/services";

const Quote = (props) => {
  const serviceSlug = props.match.params.serviceslug;

  const qouteServices = SERVICES.filter(
    (service) => service.slug === serviceSlug
  );
  return (
    <div className="quote">
      <PageHeader
        pageTitle={qouteServices[0].title}
        backgroundImage={PageHeaderImg}
      />
      <div className="content">
        <EndOfLease />
      </div>
    </div>
  );
};

export default Quote;
