import React from "react";
import "./DashboardLanding.css";
import { DashboardHeader, DashServicesItem } from "../../Dashboard";

const WorkerDashboardLanding = () => {
  const ITEMS = [
    { header: "My Sites", slug: "sites" },
    // { header: "My Documents", slug: "documents" },
    // { header: "My Payslips", slug: "payslips" },
    { header: "Feedback & Review", slug: "feedback-and-reviews" },
    // { header: "Complaint Manager & Support", slug: "support" },
    // { header: "Upload Documents", slug: "upload" },
  ];

  return (
    <div className="dashboardLanding">
      <DashboardHeader header="DASHBOARD" />
      <div className="dashboardLanding__body">
        <ul>
          {ITEMS.map(({ header, slug }, index) => (
            <DashServicesItem key={index} header={header} slug={`${slug}`} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WorkerDashboardLanding;
