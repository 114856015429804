import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { PageHeader } from "../../components/Layout";
import { FaPhoneAlt, MdEmail, FiMapPin } from "react-icons/all";
import HeaderImage from "../../assets/contact-us-header.png";
import "./Contact.css";
import axios from "axios";

const { TextArea } = Input;

const Contact = () => {
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onFinish = (values) => {
    let msg =
      "The user " +
      values.name +
      " with subject of " +
      values.subject +
      " and with email " +
      values.email +
      ". has sent you the following <b>Feedback</b>: <br />" +
      values.message +
      ". ";

    let mailapidata = {
      name: values.name,
      requestfor: "Feedback Message",
      message: msg,
    };
    console.log("message quote:", mailapidata);
    try {
      // Email Notification
      axios
        .post("https://gcc-mail-support.herokuapp.com/api/request", mailapidata)
        .then(() => {
          setIsSubmitted(false);
          form.resetFields();
          message.success("Feedback Sent Successfully.");
          message.success("Thank You For Your Feedback.");
        });
    } catch (e) {
      setIsSubmitted(false);
      message.error("Feedback Error!!");
      message.error("Please Try Again Later!!");
    }
  };

  return (
    <>
      <PageHeader pageTitle="Contact Us" backgroundImage={HeaderImage} />
      <div className="contact-container">
        <div className="content">
          <div className="contact-header">
            <p>WE ARE HERE FOR YOU</p>
            <h1>
              GET IN <span>TOUCH</span>
            </h1>
          </div>
          <div className="contact-form-container">
            <div className="contact-form">
              <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please enter your name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="subject"
                  label="Subject"
                  rules={[{ required: true, message: "Please enter subject" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email address",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="message"
                  label="Message"
                  rules={[{ required: true, message: "Please enter message" }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="bg-dark submit-button">
                    {isSubmitted ? <Spin style={{ color: "white" }} /> : null}{" "}
                    SEND
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="contact-info">
              <div className="info-with-icon">
                <FaPhoneAlt />
                <div>
                  <a href="tel:0481422769">0481 422 769( 24 hours )</a>
                </div>
              </div>
              <div className="info-with-icon">
                <MdEmail />
                <div>
                  <a href="mailto:info@greencommercialcleaning.com.au">
                    info@greencommercialcleaning.com.au
                  </a>
                </div>
              </div>
              <div className="info-with-icon">
                <FiMapPin />
                <div>Suite 5.2, 1 Palmerston Grove, Oakleigh, VIC-3166</div>
              </div>
              <div className="contact-map">
                <iframe
                  // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50383.343500723306!2d145.02904321079018!3d-37.88464224380204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66839b2d3cc5b%3A0x8dfefe212b166b8!2sDandenong%20Rd%2C%20Malvern%20East%20VIC%203145%2C%20Australia!5e0!3m2!1sen!2snp!4v1626951814015!5m2!1sen!2snp"
                  src="https://maps.google.com/maps?q=1%20Palmerston%20Grove,%20Oakleigh,%20VIC-3166&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  title="Green Commercial Cleaning"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
