import React from "react";
import { WorkerDashboard } from "../../components/UI";

const EmployeeDashboard = () => {
  return (
    <div className="workerDashboard">
      <WorkerDashboard />
    </div>
  );
};

export default EmployeeDashboard;
