import React, { useEffect } from "react";
import "./Documents.css";
import { Document, DashboardHeader } from "../../Dashboard";
import { useData } from "../../../../contexts/datacontext";

const Documents = (props) => {
  const { DocumentList, documentslist } = useData();

  useEffect(() => {
    DocumentList();
  }, []);

  return (
    <div className="documents">
      <DashboardHeader
        header="My Documents"
        goBack={props.history.push}
        isNested
      />
      {documentslist ? (
        <Document header="Document Name" datas={documentslist} />
      ) : (
        <div>No Documents Uploaded</div>
      )}
    </div>
  );
};

export default Documents;
