import React from "react";
import "./Covid.css";
import { PageHeader } from "../../components/Layout";
import HeaderImage from "../../assets/covid-header.png";
import { CovidCleaner, CovidService } from "../../components/UI/";

const Covid = () => {
  return (
    <>
      <PageHeader pageTitle="Covid 19" backgroundImage={HeaderImage} />
      <div className="covid">
        <div className="content">
          <div className="covid__header">
            <h1>
              <span>COVID-19 DISINFECTION</span> SERVICE
            </h1>
          </div>
          <div className="covid__subtitle">
            <p>
              In the amidst of global pandemic, everyone should take care of
              health. Nothing is more important than safety of your family and
              friends. We GCC is here to help you to disinfect your home,
              instituation, aged care center, hospitality facilities, medical
              center and your other surrounding areas to keep you away from
              virus.{" "}
            </p>
          </div>
          <CovidCleaner />
        </div>
      </div>
      <CovidService />
    </>
  );
};

export default Covid;
