import React,{useState} from "react";
import "./DocumentReport.css";
// import { useAuth } from "../../../../contexts/authcontexts";
import ReportRecord from "../ReportRecord/ReportRecord"
import {Button } from "antd"
const Document = ({
  header,
  subHeader = "Date Generated",
  datas,
  isDocument,
  isSite,
}) => {
  const [visible, setVisible] = useState(7);
  const handleClick= ()=>{
 setVisible((prevValue) => prevValue + 7);
  }
  return (
    <>
    
    <div className="documentreport">
      <div className="documentreport__body">
        <div className="documentreport__bodyHead">
          <p>{header}</p>
          <p>{subHeader}</p>
          <p>Options</p>
        </div>
      
          <ul>
            {datas.slice(0, visible).map(({ docType, date,id,url,path }, index) => (
              <li key={index}>
                <ReportRecord name={docType} date={date} uid={id} url={url} path={path} />
              </li>
            ))}
          </ul>
      </div>  
      <button style={{width:"100px",border:"none",backgroundColor:"green",color:"white",marginLeft:"43%"}} onClick={handleClick}>Show More</button>    
    </div>
   
    </>
  );
};

export default Document;
