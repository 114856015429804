import React from "react";
import AboutUsIntroImg from "../../../assets/about-us-introduction.png";
import "./AboutUsIntro.css";

const AboutUsIntro = () => {
  return (
    <div className="content">
      <div className="about-us-intro-container">
        <div className="intro-image-container">
          <img src={AboutUsIntroImg} alt="Green Commercial Cleaning" />
        </div>
        <div className="intro-text-container">
          <p className="pre-header">WELCOME TO</p>
          <h1 className="header">GREEN COMMERCIAL CLEANING</h1>
          <p className="intro-description">
            GCC is a proudly Australian owned company that provides
            comprehensive cleaning services, efficiently and effectively to
            Commercial, Industrial, Retail and Public Facilities. We strive to
            develop long standing relationships with our valued clients by
            ensuring the highest standards and most effective services. GCC
            recommends our client to prioritise environment friendly chemicals
            as our company is focused on environment friendly solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsIntro;
