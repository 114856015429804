import React, { useEffect } from "react";
import "./Sites.css";
import { Siteslist } from "../";
import { DashboardHeader } from "../../Dashboard";
import { useData } from "../../../../contexts/datacontext";

const Sites = (props) => {
  const { SitesList, sitesList } = useData();

  useEffect(() => {
    SitesList();
  }, []);

  return (
    <div className="sites">
      <DashboardHeader header="My Sites" goBack={props.history.push} isNested />
      {sitesList ? (
        <Siteslist
          header="Sites Name"
          subHeader="Upcoming Events"
          datas={sitesList}
        />
      ) : (
        <div>No sites available.</div>
      )}
    </div>
  );
};

export default Sites;
