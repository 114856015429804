import React from "react";
import "./UserDashboard.css";
import { Dashboard } from "../../components/UI";

const UserDashboard = () => {
  return (
    <div className="userDashboard">
      <Dashboard />
    </div>
  );
};

export default UserDashboard;
