import React from "react";
import "./Sidebar.css";
import { NavLink, Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/all";
import logo from "../../../../assets/green_commercial_logo 1.png";
import { useAuth } from "../../../../contexts/authcontexts";

const Sidebar = ({ isMobMenuVisible, showMobMenu }) => {
  const { currentUser } = useAuth();

  return (
    <div className="sidebar">
      <div className="sidebar__menu">
        <div className="sidebar__logo">
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="sidebar__tabs">
          <NavLink
            to={`/${currentUser.claims.role}/dashboard`}
            exact={true}
            activeClassName="sidebar__activeNavLink"
          >
            Dashboard
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/notifications`}
            activeClassName="sidebar__activeNavLink"
          >
            Notifications
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/terms-and-conditions`}
            activeClassName="sidebar__activeNavLink"
          >
            Terms and Conditions
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/services`}
            activeClassName="sidebar__activeNavLink"
          >
            Our Services
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/contact-us`}
            activeClassName="sidebar__activeNavLink"
          >
            Contact Us
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/help`}
            activeClassName="sidebar__activeNavLink"
          >
            Onboarding Help
          </NavLink>
        </div>
      </div>

      <div
        className={
          isMobMenuVisible
            ? "sidebar__mobMenu sidebar__mobileMenuActive"
            : "sidebar__mobMenu"
        }
      >
        <div className="sidebar__mobMenuClose">
          <AiOutlineCloseCircle onClick={showMobMenu} />
        </div>
        <div className="sidebar__logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="sidebar__tabs" onClick={showMobMenu}>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard`}
            exact={true}
            activeClassName="sidebar__activeNavLink"
          >
            Dashboard
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/notifications`}
            activeClassName="sidebar__activeNavLink"
          >
            Notifications
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/terms-and-conditions`}
            activeClassName="sidebar__activeNavLink"
          >
            Terms and Conditions
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/services`}
            activeClassName="sidebar__activeNavLink"
          >
            Our Services
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/contact-us`}
            activeClassName="sidebar__activeNavLink"
          >
            Contact Us
          </NavLink>
          <NavLink
            to={`/${currentUser.claims.role}/dashboard/help`}
            activeClassName="sidebar__activeNavLink"
          >
            Onboarding Help
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
