import React from "react";
import "./OnboardingHelp.css";
import { DashboardHeader } from "../../Dashboard";

const WorkerOnboardingHelp = () => {
  return (
    <div className="onboardingHelp">
      <DashboardHeader header="Onboarding Help" />
    </div>
  );
};

export default WorkerOnboardingHelp;
