import React from "react";
import { PageHeader } from "../../components/Layout";
import { VacancyCard, GetQoute } from "../../components/UI";
import PageHeaderImg from "../../assets/careers-page-header.png";
import VACANCIES from "../../storage/vacancy.json";
import "./Careers.css";

const Careers = () => {
  return (
    <>
      <PageHeader pageTitle="Careers" backgroundImage={PageHeaderImg} />
      <div className="careers-container">
        <div className="content">
          <div className="careers-info">
            <h1 className="careers-info-header">
              JOIN <span>OUR TEAM</span>
            </h1>
            <div className="careers-info-description">
              <p>
                GCC is a proudly Australian owned company that provides
                comprehensive cleaning services, efficiently and effectively to
                Commercial, Industrial, Retail and Public Facilities. We strive
                to develop long standing relationships with our valued clients
                by ensuring the highest standards and most effective services.
                GCC recommends our client to prioritise environment friendly
                chemicals as our company is focused on environment friendly
                solutions.
              </p>
            </div>
          </div>
          <div className="vacancies-container">
            <h2 className="vacancies-container-header">JOB OPPORTUNITIES</h2>
            <div className="vacancies-list">
              {VACANCIES.map((vacancy) => (
                <VacancyCard vacancy={vacancy} key={vacancy.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <GetQoute />
    </>
  );
};

export default Careers;
