import React from "react";
import "./OurServices.css";
import { DashboardHeader } from "..";
import { DashServicesItem } from "../DashServicesItem";
import { SERVICES } from "../../../../storage/services";

const OurServices = () => {
  return (
    <div className="ourServices">
      <DashboardHeader header="Our Services" />
      <div className="ourServices__body">
        <ul>
          {SERVICES.map(({ title, slug }, index) => (
            <DashServicesItem
              key={index}
              header={title}
              slug={`services/${slug}`}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OurServices;
