import React from 'react';
import './EndOfLeaseCard.css';

const EndOfLeaseCard = ({width}) => {
    return (
        <div className="Card_container" style={{"width" :{width}}}>
            <h2>End of Lease <br /> Cleaning</h2>
            <div className="EOL_Card_Header"> 
            </div>
            <hr />
            <div className="EOL_Card_content">
                <div className="EOL_card_details">
                        <h4>Service Details:</h4>
                        <p>Studio</p>
                        <p>Units</p>
                        <p>House</p>

                </div>
            </div>
            
        </div>
    )
}

export default EndOfLeaseCard
