import { Button } from "antd";
import React from "react";
import { IoCallOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./PageHeader.css";

const PageHeader = ({ pageTitle, backgroundImage, headerText }) => {
  return (
    <div
      className="page-header-container"
      style={{
        backgroundImage: `linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.6) 0%,
            rgba(251, 251, 251, 0.5) 45%,
            rgba(251, 251, 251, 0) 55%,
            rgba(255, 255, 255, 0) 100%
          ),
          url(${backgroundImage})`,
      }}
    >
      <div className="overlay"></div>
      <div className="header-content">
        <div className="header">{pageTitle}</div>
        <div className="breadcrumb">
          <Link to="/">Home</Link>
          {pageTitle}
        </div>
        <div className="header_text">
          <p>{headerText}</p>
        </div>

        <div className="header_button">
          <h3>Call us</h3>
          <a href="tel:0481422769">
            <Button className="bg-dark call_btn">
              <IoCallOutline />
              0481 422 769
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
