import React, { useState, useRef } from "react";
import "./VacancyCard.css";
import { Button, Modal, Form, Input, Spin, message } from "antd";

import axios from "axios";

const VacancyCard = ({ vacancy }) => {
  const fileRef = useRef();
  const [fileList, setFileList] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [step, setStep] = useState(1);

  const [form] = Form.useForm();
  const [fileInputKey, setFileInputKey] = useState("");

  const resetFileInputKey = () => {
    let randomString = Math.random().toString(36);

    setFileInputKey(randomString);
  };

  const handleUpload = (e) => {
    if (
      ["jpg", "jpeg", "png", "pdf", "doc", "docx"].includes(
        e.target.files[0].name.split(".").pop()
      )
    ) {
      setFileList(e.target.files[0]);
    } else {
      message.error("Please select valid file format");
      resetFileInputKey();
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
    setStep(1);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const formdata = new FormData();
    formdata.append("vacancyName", vacancy.title);
    formdata.append("name", values.fullName);
    formdata.append("suburb", values.suburb);
    formdata.append("contactNumber", values.contactNumber);
    formdata.append("resume", fileList);

    console.log("vacancy api data :", formdata);

    try {
      axios({
        method: "post",
        url: "https://gcc-vacancy-api.herokuapp.com/vacancy",
        data: formdata,
        headers: { "Content-Type": "multi-part/form-data" },
      })
        .then(() => {
          setStep(step + 1);
          message.success("Message submitted successfully.");
          form.resetFields();
          setSubmitting(false);
        })
        .catch((error) => {
          message.error("Application submission failed");
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (e) {
      message.error(e);
      setSubmitting(false);
    }

    // form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const switchForm = () => {
    switch (step) {
      case 1:
        return (
          <Form
            id="careersAppication"
            name="asdasd"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                { required: true, message: "Please input your full name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Suburb"
              name="suburb"
              rules={[{ required: true, message: "Please input your suburb!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Number"
              name="contactNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your contact number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Email"
              name="contactEmail"
              rules={[
                { required: true, message: "Please input your contact email!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Attach Resume" name="resume">
              <Input
                type="file"
                name="resume"
                ref={fileRef}
                key={fileInputKey}
                onChange={handleUpload}
                accept=".jpg, .jpeg, .doc, .docx, .png, .pdf"
                required
              />
              We accept PDF, DOC, DOCX, JPG and PNG files.
            </Form.Item>
          </Form>
        );
      case 2:
        return (
          <>
            <p style={{ paddingBottom: "0.2rem" }}>
              Thank you for your application.
            </p>
            <p> We will get back to you as soon as possible.</p>
          </>
        );
    }
  };

  return (
    <div className="vacancy-card-container">
      <div className="vacancy-card-content">
        <h5 className="vacancy-header">{vacancy.title}</h5>
        <p className="vacancy-description">{vacancy.description}</p>
        <div className="vacancy-features">
          <div className="vacancy-features-list">
            {vacancy.features.map((feature) => (
              <div key={feature}>
                <span />
                {feature}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="vacancy-card-apply">
        <Button className="bg-dark apply-button" onClick={showModal}>
          APPLY
        </Button>
        <p className="vacancy-location">{vacancy.location}</p>
      </div>
      <Modal
        title="Applicant's Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            className={step === 1 ? "bg-light" : "btn-hidden"}
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            className={step === 1 ? "bg-dark" : "btn-hidden"}
            key="submit"
            htmlType="submit"
            form="careersAppication"
          >
            {submitting ? <Spin style={{ color: "white" }} /> : null} Submit
          </Button>,
        ]}
      >
        {switchForm()}
      </Modal>
    </div>
  );
};

export default VacancyCard;
