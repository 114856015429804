import React from "react";
import ImageGallery from "react-image-gallery";

export const ImagesGallery = ({ service }) => {
  const images = [];

  for (const img of service[0].images) {
    images.push({
      original: img,
      thumbnail: img,
    });
  }
  return <ImageGallery items={images} />;
};

export default ImagesGallery;
