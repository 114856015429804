import React from "react";
import "./DashboardHeader.css";
import { HeaderButton } from "..";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../contexts/authcontexts";

const DashboardHeader = ({ header, isNested, goBack, isService, isSite }) => {
  const { currentUser } = useAuth();

  return (
    <div className="dashboardHeader">
      <div className="dashboardHeader__head">
        {isNested && (
          <div className="dashboardHeader__backBtn">
            <Button
              className="bg-light"
              onClick={() =>
                isService
                  ? goBack(`/${currentUser.claims.role}/dashboard/services`)
                  : isSite
                  ? goBack(`/${currentUser.claims.role}/dashboard/sites`)
                  : goBack(`/${currentUser.claims.role}/dashboard`)
              }
            >
              <ArrowLeftOutlined />
            </Button>
          </div>
        )}

        <h1>{header}</h1>
      </div>
      <div className="dashboardHeader__btn">
        <HeaderButton />
      </div>
    </div>
  );
};

export default DashboardHeader;
