import React, { useState } from "react";
import { Form, Select, Input, Button, message, Spin } from "antd";
import { SERVICES } from "../../../storage/services";
import GetQuoteImg from "../../../assets/get-quote-email.png";
import "./GetQuoteEmailForm.css";
import axios from "axios";

const { Option } = Select;

const GetQuoteEmailForm = () => {
  const [form] = Form.useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleQuoteFinish = (values) => {
    console.log("Quote values:", values);
    setIsSubmitted(true);
    let msg =
      "The user " +
      values.contactEmail +
      " with business name of " +
      values.businessName +
      " wants a quote in cleaning service of " +
      values.typeOfCleaning +
      ". His/er contact number is:" +
      values.contactNumber +
      ". ";
    let mailapidata = {
      name: values.contactEmail,
      requestfor: "Quote Request",
      message: msg,
    };
    console.log("message quote:", msg);
    console.log("message api data:", mailapidata);
    try {
      // Email Notification
      axios
        .post("https://gcc-mail-support.herokuapp.com/api/request", mailapidata)
        .then(() => {
          setIsSubmitted(false);
          form.resetFields();
          message.success("Quote Requested Successfully.");
          message.success("We Will Contact You Soon.");
        });
    } catch (e) {
      setIsSubmitted(false);
      message.error("Quote Request Error!!");
      message.error("Please Try Again Later!!");
    }
  };
  return (
    <div className="email-quote-container">
      <div className="get-quote-image-container">
        <img src={GetQuoteImg} alt="Get quote in email" />
      </div>
      <div className="description">
        <p>
          Provide your email address below and get a quote proposal within 24
          Hour from us.
        </p>
      </div>
      <div className="email-quote-form-container">
        <Form
          layout="vertical"
          className="email-quote-form"
          onFinish={handleQuoteFinish}
          form={form}
        >
          <Form.Item
            name="typeOfCleaning"
            rules={[
              { required: true, message: "Please select type of cleaning" },
            ]}
          >
            <Select placeholder="Type of Cleaning">
              {SERVICES.map((service) => (
                <Option value={service.title} key={service.slug}>
                  {service.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="contactEmail"
            rules={[
              {
                required: true,
                message: "Please enter contact email",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Contact Email" />
          </Form.Item>
          <Form.Item
            name="contactNumber"
            rules={[
              {
                required: true,
                message: "Please enter contact number",
              },
            ]}
          >
            <Input placeholder="Contact Number" />
          </Form.Item>
          <Form.Item
            name="businessName"
            rules={[
              {
                required: true,
                message: "Please enter Business Name",
              },
            ]}
          >
            <Input placeholder="Business Name" />
          </Form.Item>
          <Form.Item className="get-quote-button-container">
            <Button className="get-quote-button bg-dark" htmlType="submit">
              {isSubmitted ? <Spin style={{ color: "white" }} /> : null} REQUEST
              A QUOTE
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default GetQuoteEmailForm;
