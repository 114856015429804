import React, { useState } from "react";
import { Row, Col, Radio, Space } from "antd";
import "./EndOfLeasePrimary.css";
import { RadioDropOption } from "..";

const OPTIONS1 = [
  {
    header: "Bedroom(s)",
  },
  {
    header: "Bathroom(s)",
  },
];

const OPTIONS2 = [
  {
    header: "Bedroom(s)",
  },
  {
    header: "Bathroom(s)",
  },
  {
    header: "Floor(s)",
  },
];

const EndOfLeasePrimary = () => {
  const [Radio1, setRadio1] = useState(false);
  const [Radio2, setRadio2] = useState(false);

  const handleDropDown = (e) => {
    const target = e.target.value;
    switch (target) {
      case 1:
        setRadio1(false);
        setRadio2(false);
        break;
      case 2:
        setRadio1(true);
        setRadio2(false);
        break;
      case 3:
        setRadio2(true);
        setRadio1(false);
        break;
      default:
        break;
    }
  };
  return (
    <div className="EOL_content">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="EndOfLeasePrimary_container">
            <h2>Please tell us about your place:</h2>
            <div className="EndOfLeasePrimary_content">
              <Radio.Group>
                <Space direction="vertical">
                  <div className="radio_option">
                    <Radio value={1} onChange={handleDropDown}>
                      Studio
                    </Radio>
                  </div>
                  <div className="radio_option">
                    <Radio value={2} onChange={handleDropDown}>
                      Unit
                    </Radio>
                  </div>

                  {Radio1 && (
                    <div className="radioUnit_conatainer">
                      {OPTIONS1.map(({ header }, index) => (
                        <RadioDropOption key={index} header={header} />
                      ))}
                    </div>
                  )}
                  <div className="radio_option">
                    <Radio value={3} name="radio2" onChange={handleDropDown}>
                      House
                    </Radio>
                  </div>

                  {Radio2 && (
                    <div className="radioUnit_conatainer">
                      {OPTIONS2.map(({ header }, index) => (
                        <RadioDropOption key={index} header={header} />
                      ))}
                    </div>
                  )}
                </Space>
              </Radio.Group>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EndOfLeasePrimary;
