import React from "react";
import "../Record/Record.css";
import { Button } from "antd";
import {  ArrowDownOutlined } from "@ant-design/icons";
const Record = ({ name, date,uid,url,path}) => {

var splitTest = path.split("uploads/reports/");

  return (
    <>
    <div className="record">
      <div className="record__name">{splitTest}</div>
      <div className="record__date">{date}</div>

      <div className="record__options">
        
          <>
            <div className="record__btn">
              <Button className="bg-dark" onClick={()=> window.open(`${url}`, "_blank")}>
                <ArrowDownOutlined />
              </Button>
            </div>
           
          </>
        
      </div>
     
    </div>
 
    </>
  );
};

export default Record;
