import React from "react";
import PageHeader from "./../../components/Layout/PageHeader/PageHeader";
import "./ServiceDetail.css";
import { ServiceCategory } from "../../components/UI/ServiceCategory";
import GetQoute from "../../components/UI/GetQoute/GetQoute";
import { SERVICES } from "./../../storage/services";
import { ImagesGallery } from "./ServiceImageGallery";

const ServiceDetail = (props) => {
  const slug = props.match.params.slug;
  const service = SERVICES.filter((service) => service.slug === slug);

  return (
    <div>
      <PageHeader
        pageTitle={service[0].title}
        backgroundImage={service[0].cover}
        headerText={service[0].description}
      />
      <div className="service_detail_container">
        <div className="content">
          <div className="service_detail_content_container">
            <div className="service_detail_category_container">
              <ServiceCategory />
            </div>
            <div className="service_detail_text_carousel_container ">
              {/* <div className="service_detail_text">
                <p>{service[0].description}</p>
              </div> */}
              <div className="service_detail_img_gallery">
                <ImagesGallery service={service} />
              </div>
            </div>
          </div>
          <div className="customer_offer_container">
            <div className="customer_offer_container_heading">
              <h1>{service[0].offerTitle}</h1>
            </div>

            <div className="customer_offer_container_content">
              <p>{service[0].contentTitle}</p>
              <ul>
                {service[0].contentList.map((content) => (
                  <li>{content}</li>
                ))}
              </ul>

              <p>{service[0].secondContentTitle}</p>

              <ul>
                {service[0].offerList.map((offer) => (
                  <li>{offer}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <GetQoute />
      </div>
    </div>
  );
};

export default ServiceDetail;
