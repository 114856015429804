import React, { useState } from "react";
import "./Dashboard.css";
import { Switch, Route } from "react-router-dom";
import { Sidebar } from "../Dashboard";
import { AiOutlineMenu } from "react-icons/all";
import {
  WorkerDashboardLanding,
  WorkerNotifications,
  WorkerOnboardingHelp,
  Sites,
  Payslips,
  Documents,
  SubpageSites,
} from ".";
import {
  HeaderButton,
  Service,
  FeedbackAndReviews,
  ComplaintSupport,
  UploadDocument,
  Profile,
  TermsAndConditions,
  ContactUs,
  OurServices,
} from "../Dashboard";

const WorkerDashboard = () => {
  const [isMobMenuVisible, setIsMobMenuVisible] = useState(false);

  const showMobMenu = () => setIsMobMenuVisible(!isMobMenuVisible);

  return (
    <div className="dashboard">
      <div className="dashboard__sidebar">
        <Sidebar
          isMobMenuVisible={isMobMenuVisible}
          showMobMenu={showMobMenu}
        />
      </div>
      <div className="dashboard__content">
        <div className="dashboard__header">
          <span className="dashboard__mobMenuOpen">
            <AiOutlineMenu onClick={showMobMenu} />
          </span>
          <HeaderButton />
        </div>
        <Switch>
          <Route
            path="/employee/dashboard"
            component={WorkerDashboardLanding}
            exact
          />
          <Route path="/employee/dashboard/documents" component={Documents} />
          <Route path="/employee/dashboard/sites" component={Sites} exact />
          <Route
            path="/employee/dashboard/sites/upload/:slug"
            component={SubpageSites}
          />

          <Route path="/employee/dashboard/payslips" component={Payslips} />

          <Route
            path="/employee/dashboard/feedback-and-reviews"
            component={FeedbackAndReviews}
          />
          <Route
            path="/employee/dashboard/support"
            component={ComplaintSupport}
          />
          <Route path="/employee/dashboard/upload" component={UploadDocument} />
          <Route path="/employee/dashboard/profile" component={Profile} />
          <Route
            path="/employee/dashboard/notifications"
            component={WorkerNotifications}
          />
          <Route
            path="/employee/dashboard/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route
            path="/employee/dashboard/services"
            component={OurServices}
            exact
          />
          <Route
            path="/employee/dashboard/services/:slug"
            component={Service}
          />
          <Route path="/employee/dashboard/contact-us" component={ContactUs} />
          <Route
            path="/employee/dashboard/help"
            component={WorkerOnboardingHelp}
          />
        </Switch>
      </div>
    </div>
  );
};

export default WorkerDashboard;
