import React from "react";
import GetQoute from "../../components/UI/GetQoute/GetQoute";
import "./Service.css";
import ServiceCards from "./../../components/UI/Card/Cards/index";
import { Col, Row } from "antd";
import { ServiceCategory } from "../../components/UI/ServiceCategory";
import { PageHeader } from "../../components/Layout";
import PageHeaderImg from "../../assets/careers-page-header.png";

const Services = () => {
  return (
    <>
      <PageHeader pageTitle="Services" backgroundImage={PageHeaderImg} />
      <div className="service_container">
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={24} md={24} lg={8}>
            <ServiceCategory />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16}>
            <ServiceCards />
          </Col>
        </Row>
      </div>
      <GetQoute />
    </>
  );
};

export default Services;
