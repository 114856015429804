import React from "react";
import "./ServiceCategory.css";
import { SERVICES } from "../../../storage/services";
import { Link } from "react-router-dom";

const ServiceCategory = () => {
  return (
    <div>
      <div className="service_category">
        <div className="service_category_header">
          <h1>Services</h1>
        </div>
        <div className="service_category_body">
          <div className="service_category_bodyItem">
            <ul>
              {SERVICES.map(({ title, slug }) => (
                <li key={slug}>
                  <Link to={`/services/${slug}`} key={slug}>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCategory;
