import React from "react";
import "./Payslips.css";
import { DashboardHeader, Document } from "../../Dashboard";

const Payslips = (props) => {
  const documentslist = [];

  return (
    <div className="payslips">
      <DashboardHeader
        header="My Payslips"
        goBack={props.history.push}
        isNested
      />
      {documentslist ? (
        <Document header="Report Name" datas={documentslist} />
      ) : (
        <div>No Payslips available.</div>
      )}
    </div>
  );
};

export default Payslips;
