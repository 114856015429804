import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  GoMailRead,
  FiPhoneCall,
  FaFacebookF,
  AiOutlineMenu,
  IoIosArrowDropdown,
  AiOutlineCloseCircle,
} from "react-icons/all";
import { SERVICES } from "../../../storage/services";
import logo from "../../../assets/green_commercial_logo 1.png";
import requestQuoteBg from "../../../assets/request-quote-bg.png";
import "./NavBar.css";
import { Modal, Tabs, Button } from "antd";
import { GetQuoteEmailForm, GenerateQuoteForm } from "../../UI";

import { useAuth } from "../../../contexts/authcontexts";
import { HeaderButton } from "../../UI/Dashboard/HeaderButton";

const NavBar = () => {
  const { currentUser } = useAuth();
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isMobMenuVisible, setIsMobMenuVisible] = useState(false);

  const [isMobSubMenuVisible, setIsMobSubMenuVisible] = useState({});

  const handleMobSubMenu = (itemName) => {
    setIsMobSubMenuVisible((prevValue) => {
      const temp = { ...prevValue };
      temp[itemName] = !temp[itemName];
      return temp;
    });
  };

  return (
    <>
      <div className="navbar-container">
        <div className="top-navbar">
          <div className="navbar-logo">
            <Link to="/">
              <img
                src={logo}
                alt="Green Commercial Cleaning"
                className="logo"
              />
            </Link>
          </div>
          <div className="top-header-info">
            <div className="top-header-info-item">
              <div className="top-header-info-item-icon">
                <a href="mailto:info@greencommercialcleaning.com.au">
                  <GoMailRead />
                </a>
              </div>
              <div className="top-header-info-item-detail">
                <p>Mail Us:</p>
                <a href="mailto:info@greencommercialcleaning.com.au">
                  info@greencommercialcleaning.com.au
                </a>
              </div>
            </div>
            <div className="top-header-info-item">
              <div className="top-header-info-item-icon">
                <a href="tel:0481422769">
                  <FiPhoneCall />
                </a>
              </div>
              <div className="top-header-info-item-detail">
                <p>Call Us:</p>
                <a href="tel:0481422769">0481 422 769</a>
              </div>
            </div>
            <div className="top-header-info-item">
              <div className="social">
                <a href="https://www.facebook.com/greencommercialcleaning">
                  <FaFacebookF />
                </a>
              </div>
            </div>

            <div className="top-header-info-item">
              {currentUser ? (
                <HeaderButton />
              ) : (
                <Link to="/login">
                  <Button className="bg-dark">LOGIN</Button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="navbar-menu">
          <div className="navbar-menu-toggle">
            <AiOutlineMenu
              onClick={() => setIsMobMenuVisible((prevVal) => !prevVal)}
            />
          </div>
          <ul className="menu-list">
            <li className="menu-item">
              <NavLink activeClassName="activeNavLink" exact={true} to="/">
                <div className="top-bar" />
                Home
              </NavLink>
            </li>
            <li className="menu-item has-sub-menu">
              <NavLink
                activeClassName="activeNavLink"
                exact={true}
                to="/services"
              >
                <div className="top-bar" />
                Services
              </NavLink>
              <div className="sub-menu">
                <ul className="sub-menu-list">
                  {SERVICES.map(({ Icon, title, slug }) => (
                    <li key={slug}>
                      <Link to={`/services/${slug}`} className="sub-menu-item">
                        <div>
                          <Icon />
                        </div>
                        <div>{title}</div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="activeNavLink"
                exact={true}
                to="/about-us"
              >
                <div className="top-bar" />
                About Us
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="activeNavLink"
                exact={true}
                to="/careers"
              >
                <div className="top-bar" />
                Careers
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="activeNavLink"
                exact={true}
                to="/contact"
              >
                <div className="top-bar" />
                Contact
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="activeNavLink"
                exact={true}
                to="/covid-19"
              >
                <div className="top-bar" />
                Covid 19
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="activeNavLink"
                exact={true}
                to="/services/post-builders-cleaning"
              >
                Post Construction Cleaning
              </NavLink>
            </li>
          </ul>
          <div className="request-quote-block" onClick={showModal}>
            <img src={requestQuoteBg} alt="" />
            <Link>REQUEST A QUOTE</Link>
          </div>
        </div>
      </div>
      <div className={`mob-menu ${isMobMenuVisible ? "visible" : ""}`}>
        <AiOutlineCloseCircle
          className="mob-menu-close"
          onClick={() => setIsMobMenuVisible((prevVal) => !prevVal)}
        />
        <ul className="menu-list">
          <li className="menu-item">
            <NavLink activeClassName="activeNavLink" exact={true} to="/">
              Home
            </NavLink>
          </li>
          <li className="menu-item has-sub-menu">
            <NavLink
              activeClassName="activeNavLink"
              exact={true}
              to="/services"
            >
              Services
              <IoIosArrowDropdown
                className="mob-menu-dropdown"
                onClick={() => handleMobSubMenu("Services")}
              />
            </NavLink>
            <div
              className={`sub-menu ${
                isMobSubMenuVisible["Services"] ? "visible" : ""
              }`}
            >
              <ul className="sub-menu-list">
                {SERVICES.map(({ Icon, title, slug }) => (
                  <li className="sub-menu-item" key={slug}>
                    <Link to={`/services/${slug}`}>
                      <Icon />
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li className="menu-item">
            <NavLink
              activeClassName="activeNavLink"
              exact={true}
              to="/about-us"
            >
              About Us
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="activeNavLink" exact={true} to="/careers">
              Careers
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="activeNavLink" exact={true} to="/contact">
              Contact
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              activeClassName="activeNavLink"
              exact={true}
              to="/covid-19"
            >
              Covid 19
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              activeClassName="activeNavLink"
              exact={true}
              to="/services/post-builders-cleaning"
            >
              Post Construction Cleaning
            </NavLink>
          </li>
        </ul>
      </div>
      <Modal
        title={false}
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        style={{ top: 20 }}
      >
        <Tabs defaultActiveKey="1" centered className="services-quote">
          <TabPane tab="GET QUOTE USING EMAIL ADDRESS" key="1">
            <GetQuoteEmailForm />
          </TabPane>
          <TabPane tab="USE OUR QUOTE ESTIMATOR TOOL" key="2">
            <GenerateQuoteForm />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default NavBar;
