import React from "react";
import MessageEditor from "./../../MessageEditor/MessageEditor";
import DashboardHeader from "./../../DashboardHeader/DashboardHeader";

const RequestRegularServices = (props) => {
  const dropOptions = [
    "Carpet Cleaning",
    "Windows CLeaning",
    "Covid disinfection and Sanitization",
    "Pressure wash",
    "Car park Cleaning",
    "Flood and leakage management",
    "Waste Management",
    "Floor Treatment",
    "Other Services(Custom)",
    "Toilet Requisites Management",
  ];

  return (
    <>
      <div className="request_service">
        <DashboardHeader
          header="Request Regular Services"
          goBack={props.history.push}
          isNested
        />
        <MessageEditor
          dropOptions={dropOptions}
          selectMessage="Select one of the services"
          placeholder="Choose regular services"
          requestType="Request Regular Service"
        />
      </div>
    </>
  );
};

export default RequestRegularServices;
