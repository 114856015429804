import React,{useState,useEffect} from 'react'
import "./NotificationBody.css"
import { Avatar} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { db } from '../../../../firebase';

function NotificationBody({id,message,seen,type,url,docid}) {

  const[color,setColor] = useState('#f1f2fa');

    const changecolor =()=>{
      if(seen === true){
        setColor('white')
      }
      else{
        setColor('f1f2fa')
      }
    }
   
    const handleNotification =async(url,docid)=>{ 
        await   db.collection("notifications").doc(docid).update({seen:true})
        window.open(`${url}`, "_blank")
    }

    useEffect(()=>{
      changecolor()
    },[seen])
    return (
        <>
        <div className="notificationbody" style={{background:color}} onClick={()=>handleNotification(url,docid)}>
        <div className="notificationicon">
            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
        </div>
        <div  className="notificationitem">{message}</div>
        <div className="notificationactive"></div>
       

        </div>
          
        </>
    )
}

export default NotificationBody
