import React from "react";
import "./SubpageSites.css";
import { useLocation } from "react-router-dom";
import { DashboardHeader } from "../../Dashboard";
import { UploadFileSites } from "..";

const SubpageSites = (props) => {
  const location = useLocation();

  return (
    <div className="subpageSites">
      <DashboardHeader
        header={location.state.name}
        goBack={props.history.push}
        isNested
        isSite
      />
      <div className="subpageSites__body">
        <h4>Job Timing: After hours</h4>
        <UploadFileSites {...props} />
      </div>
    </div>
  );
};

export default SubpageSites;
