import {
  MdChat,
  MdInsertDriveFile,
  MdPersonOutline,
  MdTouchApp,
} from "react-icons/md";

export const QouteData = [
  {
    icon: <MdTouchApp />,
    title: "Get in touch with us",
    details: "Make an enquiry online",
  },
  {
    icon: <MdChat />,
    title: "Chat with Us",
    details: "A chat to discuss and explore your needs and our services.",
  },
  {
    icon: <MdPersonOutline />,
    title: "Make Appointment",
    details:
      "A face to face appointment to understand your needs in further details.",
  },
  {
    icon: <MdInsertDriveFile />,
    title: "Recieve Quote within 24 hours",
    details:
      "After discussion, a quote proposal will be sent to you within 24 hours.",
  },
];
