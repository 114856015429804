import React from "react";
import "./SignUp.css";
import { Col, Row } from "antd";
import { SignUpFormClient } from "./../../components/UI/SignUpFormClient";
import { Welcome } from "./../../components/UI/Welcome";

const SignUp = () => {
  return (
    <>
      <div className="signup_container">
        <div className="signup_content">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <SignUpFormClient />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Welcome
                welcome_text={"Welcome"}
                button_text={"LOGIN"}
                account_text={"Already have an account? "}
                link={"login"}
                loginImg={null}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SignUp;
