import React, { useState } from "react";
import "./RadioDropOption.css";
import { Button } from "antd";

const RadioDropOption = ({ header }) => {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    setCount(count - 1);
  };

  return (
    <div className="radioDropOption">
      <p>{header}</p>
      <div className="radioDropOption__count">
        <div className="radioDropOption__btn">
          <Button
            disabled={count === 0}
            onClick={handleDecrement}
            className="bg-dark"
          >
            -
          </Button>
        </div>

        <span>{count}</span>
        <div className="radioDropOption__btn">
          <Button onClick={handleIncrement} className="button bg-dark">
            +
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RadioDropOption;
