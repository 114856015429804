import React from "react";
import "./CovidService.css";

const CovidService = () => {
  return (
    <div className="covidService">
      <p>Call us for COVID 19 Disinfection Service Today!</p>
      <div className="covidService__contacts">
        <div className="covidService__contactItem">
          <h2>
            <a href="tel:4814227690">0481 422 769</a>
          </h2>
          <p>(24 hours)</p>
        </div>
      </div>
    </div>
  );
};

export default CovidService;
