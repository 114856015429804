export const EndOfLeaseAccordionData = [
  {
    key:1,
    title: "Please be advised",
    content:
      "Detergents & equipment are included. The property needs to be free of personal belongings, if someone is still living there or if there are any personal items (except furniture) we can not performe the service. Running water and electricity must be available at the property.",
  },
  {
    key:2,
    title: "Bathrooms",
    content:
      "Clean and disinfect all floors and surfaces. Clean cupboards both inside and outside. Clean exhaust fan/vent. Remove lime-scale from any tiles, toilets, baths, showers, sinks, and shower doors or screens. Wipe down all mirrors, steel fixtures, skirting and frames.",
  },
  {
    key:3,
    title: "Bedrooms",
    content:
      "Vacuum/mop all floors.Clean inside windows. Dust blinds. Wipe window sills (inside) and tracks. Remove litter. Dust and clean all sills, ledges, skirting, and frames. Clean all cupboards/wardrobes. Remove cobwebs. Wipe down doors and mirrors.",
  },
  {
    key:4,
    title: "Kitchen",
    content:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita velit eum, exercitationem voluptate, nisi dolorum asperiores natus illum aut quidem saepe accusantium aspernatur quas. Nihil numquam sunt obcaecati facilis error?",
  },
  {
    key:5,
    title: "Living Areas and Hallways",
    content:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita velit eum, exercitationem voluptate, nisi dolorum asperiores natus illum aut quidem saepe accusantium aspernatur quas. Nihil numquam sunt obcaecati facilis error?",
  },
  {
    key:6,
    title: "How long the service take?",
    content:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita velit eum, exercitationem voluptate, nisi dolorum asperiores natus illum aut quidem saepe accusantium aspernatur quas. Nihil numquam sunt obcaecati facilis error?",
  },
  {
    key:7,
    title: "Can you guarantee my deposit back?",
    content:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita velit eum, exercitationem voluptate, nisi dolorum asperiores natus illum aut quidem saepe accusantium aspernatur quas. Nihil numquam sunt obcaecati facilis error?",
  },
  {
    key:8,
    title: "How does pricing work?",
    content:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita velit eum, exercitationem voluptate, nisi dolorum asperiores natus illum aut quidem saepe accusantium aspernatur quas. Nihil numquam sunt obcaecati facilis error?",
  },
  {
    key:9,
    title: "Fantastic Club members enjoy:",
    content:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita velit eum, exercitationem voluptate, nisi dolorum asperiores natus illum aut quidem saepe accusantium aspernatur quas. Nihil numquam sunt obcaecati facilis error?",
  },
];
