import React from "react";
import "./Services.css";
import { ServiceListItem } from "..";
import { Link } from "react-router-dom";
import { SERVICES } from "../../../storage/services";

const Services = () => {
  return (
    <div className="services">
      <div className="content">
        <div className="services__header">
          <h3>
            OUR <span>SERVICES</span>
          </h3>
        </div>
        <div className="services__body">
          <div className="services__bodyItem">
            <ul>
              {SERVICES.map(({ Icon, title, slug }) => (
                <li className="serviceItem" key={slug}>
                  <Link to={`/services/${slug}`}>
                    <ServiceListItem icon={<Icon />} header={title} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
