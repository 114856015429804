import React from "react";
import "./ComplaintSupport.css";
import { DashboardHeader, MessageEditor } from "..";

function ComplaintSupport(props) {
  const dropOptions = ["Lodge a Complaint", "Request for Support", "Others"];

  return (
    <div className="complaintSupport">
      <DashboardHeader
        header="Complaint Manager and Support"
        goBack={props.history.push}
        isNested
      />
      <MessageEditor
        dropOptions={dropOptions}
        requestType="Complaint And Support"
      />
    </div>
  );
}

export default ComplaintSupport;
