import React, { useState, useContext, createContext, useEffect } from "react";
import { message } from "antd";
import { getFormattedDate } from "../utils/getformattedDate";
import firebase, { db, storageRef } from "../firebase";
import { useAuth } from "./authcontexts";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

const DataProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [documentslist, setdocumentslist] = useState([]);
  const [recordlist, setrecordlist] = useState([]);
  const [notificationlist, setNotificationList] = useState([]);
  const [sitesList, setSitesList] = useState([]);

  const SitesList = () => {
    db.collection("users")
      .doc(currentUser.claims.user_id)
      .get()
      .then((querySnapshot) => {
        const datas = querySnapshot.data().sites;
        const items = [];

        datas?.forEach((id) => {
          db.collection("sites")
            .doc(id)
            .get()
            .then((doc) => {
              items.push(doc.data());
              setSitesList(items);
            });
        });
      })
      .catch((err) => message.error(err));
  };

  const DocumentList = (userid) => {
    db.collection("documents")
      .where("uploadedBy", "==", currentUser.claims.user_id)
      .get()
      .then((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let date = getFormattedDate(data.uploadedAt);
          items.push({
            docType: data.docType,
            date: date,
            id: doc.id,
            url: data.documents,
          });
        });

        setdocumentslist(items);
      })
      .catch((err) => message.error(err));
  };

  const RecordList = () => {
    console.log(currentUser.claims.user_id, "current user");
    db.collection("reports")
      .where("client", "==", currentUser.claims.user_id)
      .orderBy("generatedAt", "desc")

      .get()
      .then((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let date = getFormattedDate(data.generatedAt);
          items.push({
            id: doc.id,
            docType: data.client,
            date: date,
            path: data.path,
            url: data.url,
          });
        });
        setrecordlist(items);
      })
      .catch((err) => message.error(err));
  };

  const Notification = () => {
    db.collection("notifications")
      .where("userid", "==", currentUser.claims.user_id)
      .get()
      .then((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const docid = doc.id;
          items.push({
            docid: docid,
            id: data.userid,
            message: data.message,
            seen: data.seen,
            type: data.type,
            url: data.url,
          });
        });
        console.log(items, "from notification");
        setNotificationList(items);
      })
      .catch((err) => message.error(err));
  };

  const getuserinfo = (userid) => {
    console.log("inside getusername");
    db.collection("users")
      .doc(userid)
      .get()
      .then((doc) => console.log(doc.data()));
  };

  const sendFeedBack = ({ username, userRole, FeedBackType, FeedBack }) => {
    let date = firebase.firestore.Timestamp.now();
    let feedbackData = {
      userRole: userRole,
      userName: username,
      uploadedAt: date,
      FeedBackType: FeedBackType,
      FeedBackMessage: FeedBack,
    };

    db.collection("feedbacks")
      .doc()
      .set(feedbackData)
      .then(() => message.success("FeedBack Submitted Successfully."))
      .catch((err) => message.error(err));
  };

  const values = {
    DocumentList,
    documentslist,
    sendFeedBack,
    getuserinfo,
    RecordList,
    recordlist,
    Notification,
    notificationlist,
    sitesList,
    SitesList,
  };

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

export default DataProvider;
