import React from "react";
import "./FormLabel.css";
import { Form, Input } from "antd";

function FormLabel({ label, name, isPassword }) {
  return (
    <>
      <Form.Item
        className="formLabel"
        style={{ marginBottom: "12px" }}
        label={label}
        name={name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: "Invalid Input" }]}
      >
        {!isPassword ? <Input /> : <Input.Password />}
      </Form.Item>
    </>
  );
}

export default FormLabel;
