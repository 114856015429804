import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { SERVICES } from "../../../storage/services";
import logo from "../../../assets/green_commercial_logo 1.png";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-items-container">
          <div className="footer-item">
            <div className="footer-logo-container">
              <Link to="/">
                <img
                  src={logo}
                  alt="Green Commercial Cleaning"
                  className="logo"
                />
              </Link>
              <div>
                <Link to="/contact">
                  <Button className="bg-dark feedback-buton">
                    SEND FEEDBACK
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-item">
            <h3 className="footer-item-header">QUICK LINKS</h3>
            <ul className="quick-links">
              <li className="quick-link-item">
                <Link to="/">Home</Link>
              </li>
              <li className="quick-link-item">
                <Link to="/about-us">About Us</Link>
              </li>
              <li className="quick-link-item">
                <Link to="/careers">Careers</Link>
              </li>
              <li className="quick-link-item">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="quick-link-item">
                <Link to="/">Terms and Conditions</Link>
              </li>
              <li className="quick-link-item">
                <Link to="/">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="footer-item footer-services-item">
            <h3 className="footer-item-header">SERVICES</h3>
            <div className="services-list-container">
              <ul className="services-list">
                {SERVICES.map(({ Icon, title, slug }) => (
                  <li className="sub-menu-item" key={slug}>
                    <Link to={`/services/${slug}`}>
                      <Icon />
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright-container">
        <div className="copyright">
          <p>COPYRIGHT {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
