import React from "react";
import "./DashServicesItem.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/authcontexts";

const DashServicesItem = ({ header, slug }) => {
  const { currentUser } = useAuth();

  return (
    <>
      {/* {currentUser.claims.role === "client" ? (
        <Link to={`/client/dashboard/${slug}`}>
          <li className="dashServicesItem">{header}</li>
        </Link>
      ) : (
        <Link to={`/employee/dashboard/${slug}`}>
          <li className="dashServicesItem">{header}</li>
        </Link>
      )} */}
      <Link to={`/${currentUser.claims.role}/dashboard/${slug}`}>
        <li className="dashServicesItem">{header}</li>
      </Link>
    </>
  );
};

export default DashServicesItem;
