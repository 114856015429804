import React, { useState } from "react";
import { useHistory } from "react-router";
import { Form, Input, Button, Checkbox, Spin, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./LoginForm.css";
import { Link } from "react-router-dom";
import logo from "./../../../assets/green_commercial_logo 1.png";
import { useAuth } from "../../../contexts/authcontexts";

const LoginForm = () => {
  const { login, currentUser } = useAuth();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  let history = useHistory();

  const onFinish = (values) => {
    setIsLoggingIn(true);

    console.log("email:", values.email, "password:", values.password);

    login(values.email, values.password)
      .then(() => {
        currentUser
          ? history.push(`/${currentUser.claims.role}/dashboard`)
          : history.push("/login");
      })
      .catch((e) => {
        message.error(e.message);
        setIsLoggingIn(false);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });

    console.log("Received values of form: ", values);
  };

  return (
    // <div className="login_container">

    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Link to="/">
        <img src={logo} alt="Green Commercial Cleaning" className="logo" />
      </Link>

      <h2>Login</h2>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please input your email address!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Link className="login-form-forgot" to="/forgot-password">
          Forgot password?
        </Link>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" className="login-form-button bg-dark">
          {isLoggingIn ? <Spin style={{ color: "white" }} /> : null}
          Log in
        </Button>
        <p style={{ "text-align": "center", paddingTop: "10px" }}>
          Don't have an account?
        </p>
      </Form.Item>
      <Form.Item>
        <Link to="/signup-client">
          <Button className="login-form-button btn-light">
            REGISTER AS CLIENTS
          </Button>
        </Link>
      </Form.Item>

      <Form.Item>
        <Link to="/signup-worker">
          <Button className="login-form-button btn-light">
            REGISTER AS WORKER
          </Button>
        </Link>
      </Form.Item>
    </Form>
    // </div>
  );
};

export default LoginForm;
