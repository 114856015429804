import React, { useState } from "react";
import "./UploadFileSites.css";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Upload,
  Checkbox,
  Input,
  Select,
  Radio,
  message,
} from "antd";
import firebase, { db, storageRef } from "../../../../firebase";
import { useAuth } from "../../../../contexts/authcontexts";

function UploadFileSites(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [disableUpload, setDisableUpload] = useState(true);
  const [upfileListJobimages, setupFileListJobImages] = useState([]);
  const [upfileListIssueimages, setupfileListIssueimages] = useState([]);

  console.log("props", props);
  const siteid = props.match.params.slug;

  const { currentUser } = useAuth();
  console.log("current user", currentUser);

  const { TextArea } = Input;
  const { Option } = Select;
  const { Dragger } = Upload;
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const [jobstatus, setJobStatus] = useState("Job Completed");

  const onRemoveJobImages = async (file) => {
    const index = upfileListJobimages.indexOf(file);
    const newFileList = upfileListJobimages.slice();
    newFileList.splice(index, 1);

    setupFileListJobImages(newFileList);
  };

  const onRemoveIssueImages = async (file) => {
    const index = upfileListIssueimages.indexOf(file);
    const newFileList = upfileListIssueimages.slice();
    newFileList.splice(index, 1);

    setupfileListIssueimages(newFileList);
  };

  const onChangeJobImages = ({ fileList }) => {
    setupFileListJobImages(fileList);
  };

  const onChangeIssueImages = ({ fileList }) => {
    setupfileListIssueimages(fileList);
  };

  const onChangeJob = (e) => {
    setJobStatus(e.target.value);
  };

  const locationTypeChange = (value) => {
    if (value) {
      setDisableUpload(false);
    } else {
      setDisableUpload(true);
    }
  };

  const getSubmissionNumber = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        db.collection("reportinfo")
          .doc(siteid)
          .get()
          .then((doc) => {
            let numdata = doc.data();
            let subnumber = numdata.submissionCounter;
            subnumber += 1;
            resolve(subnumber);
          });
      } catch (e) {
        reject(e);
      }
    });
  };

  const onFinish = async (values) => {
    console.log("submitted values", values);
    setSubmitting(true);

    if (disableUpload) {
      setSubmitting(false);
    }

    try {
      let location = values.Location;
      let issueimages = [];
      let issueText = "";
      if (isChecked) {
        issueText = values.issue_text;
        issueimages = await Promise.all(
          upfileListIssueimages.map(async (file) => {
            try {
              const fileName = `uploads/images/${Date.now()}-${file.name}`;
              const fileRef = storageRef.child(fileName);
              const documentFile = await fileRef.put(file.originFileObj, {
                contentType: file.type,
              });
              const downloadUrl = await documentFile.ref.getDownloadURL();
              return { url: downloadUrl, path: fileName };
            } catch (e) {
              console.log(e);
            }
          })
        );
      } else {
        issueText = "";
        issueimages = [];
      }

      let uploadedAt = firebase.firestore.Timestamp.now();
      let user = currentUser.claims.user_id;
      let submissionNumber = await getSubmissionNumber();
      console.log("submission Number", submissionNumber);

      let jobimages = await Promise.all(
        upfileListJobimages.map(async (file) => {
          try {
            const fileName = `uploads/images/${Date.now()}-${file.name}`;
            const fileRef = storageRef.child(fileName);
            const documentFile = await fileRef.put(file.originFileObj, {
              contentType: file.type,
            });
            const downloadUrl = await documentFile.ref.getDownloadURL();
            return { url: downloadUrl, path: fileName };
          } catch (e) {
            console.log(e);
          }
        })
      );

      console.log("jobstatus", jobstatus);
      // structure of siteimages
      const siteimages = {
        issueimages: issueimages,
        issuetext: issueText,
        jobimages: jobimages,
        location: location,
        site: siteid,
        submissionNumber: submissionNumber,
        uploadedAt: uploadedAt,
        user: user,
        jobstatus: jobstatus,
      };

      //  update to firestore
      try {
        db.collection("siteimages")
          .doc()
          .set(siteimages)
          .then(() => {
            db.collection("reportinfo")
              .doc(siteid)
              .update({ submissionCounter: submissionNumber })
              .then(() => {
                message.success("Successfully Uploaded!");
                // After uploading
                setSubmitting(false);
                form.resetFields();
                setupFileListJobImages([]);
                setupfileListIssueimages([]);
              });
          });
      } catch (e) {
        message.warning("Upload Error!!");
        setSubmitting(false);
      }
    } catch (e) {
      message.warning("Upload Error!!");
      setSubmitting(false);
    }
  };

  return (
    <div className="uploadFileSites">
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name="Location"
          rules={[
            {
              required: true,
              message: "Please Select The Location!",
            },
          ]}
        >
          <Select
            size="large"
            style={{ fontSize: "14px" }}
            showSearch
            placeholder="Choose option"
            optionFilterProp="children"
            onChange={locationTypeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="Main Garbage Room">Main Garbage Room</Option>
            <Option value="Lifts">Lifts</Option>
            <Option value="Carpeteded Areas">Carpeteded Areas</Option>
            <Option value="Toilets">Toilets</Option>
            <Option value="Courtyard Staircases">Courtyard Staircases</Option>
            <Option value="Lobbies">Lobbies</Option>
            <Option value="Podiums:- Gymnasium/Sauna Room/Meeting Room">
              Podiums:- Gymnasium/Sauna Room/Meeting Room
            </Option>
            <Option value="Perimeter of Building">Perimeter of Building</Option>
            <Option value="Loading Dock">Loading Dock</Option>
            <Option value="Mailbox Area">Mailbox Area</Option>
            <Option value="Water Features">Water Features</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <h4>UPLOAD JOB PICTURE</h4>
          <div className="uploadFileSites__upload">
            <Dragger
              className="uploadFileSites__icon"
              maxCount={20}
              beforeUpload={() => false}
              multiple={true}
              fileList={upfileListJobimages}
              onChange={onChangeJobImages}
              onRemove={onRemoveJobImages}
              disabled={disableUpload}
            >
              <PlusOutlined />
            </Dragger>
            {/* <div className="uploadFileSites__uploadBtn">
              <Button className="bg-dark">UPLOAD</Button>
            </div> */}
          </div>
        </Form.Item>
        <Form.Item>
          <div className="uploadFileSites__query">
            <Checkbox onChange={() => setIsChecked(!isChecked)}>
              There is a problem at the site.
            </Checkbox>
          </div>
        </Form.Item>
        <Form.Item
          style={!isChecked ? { display: "none" } : { display: "block" }}
        >
          <h4>UPLOAD ISSUE PICTURE</h4>
          <div className="uploadFileSites__issueUpload">
            <Dragger
              className="uploadFileSites__icon"
              maxCount={20}
              beforeUpload={() => false}
              multiple={true}
              fileList={upfileListIssueimages}
              onChange={onChangeIssueImages}
              onRemove={onRemoveIssueImages}
              disabled={disableUpload}
            >
              <PlusOutlined />
            </Dragger>
          </div>
        </Form.Item>

        <Form.Item
          name="issue_text"
          style={!isChecked ? { display: "none" } : { display: "block" }}
        >
          <TextArea
            placeholder="Please explain the issue here.."
            style={{
              margin: "3rem 0 1rem 0",
            }}
            showCount
            maxLength={250}
            rows={10}
          />
        </Form.Item>

        <Form.Item name="job_status">
          <div className="uploadFileSites__footerBtns">
            <Radio.Group
              defaultValue="Job Completed"
              buttonStyle="solid"
              onChange={onChangeJob}
            >
              <Radio.Button value="Job Completed">Job Completed</Radio.Button>
              <Radio.Button value="Job Incomplete">Job Incomplete</Radio.Button>
            </Radio.Group>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="uploadFile__btn">
            <Button className="bg-dark" shape="round" htmlType="submit">
              {submitting ? "Uploading..." : "Upload"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default UploadFileSites;
