import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import "./Record.css";
import { Button, Modal,message } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import ViewModal from "../Model/ViewModel";
import {db} from "../../../../firebase"


  const Record = ({ name, date, isSite,uid,url }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    history.push({
      pathname: "/employee/dashboard/sites/upload",
      state: { name },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClick= (uid)=>{
   setVisible(true);
  }
  const handleDelete=async(uid)=>{
  await  db.collection("documents").doc(uid).delete().then( 
     message.success("sucessfully deleted")
    )
    .catch((err)=>{
      console.log(err,"Cannnot delete items")
    }
    )
  }



  return (
    <div className="record">
      <div className="record__name">{name}</div>
      <div className="record__date">{date}</div>

      <div className="record__options">
        {!isSite ? (
          <>
            <div className="record__btn">
              <Button className="bg-light" onClick={()=>handleClick(uid)}>
                <EyeOutlined />
              </Button>
            </div>
            <div className="record__btn">
              <Button className="bg-dark" onClick={()=>handleDelete(uid)}>
              <DeleteOutlined />
              </Button>
            </div>
          </>
        ) : (
          <>
            <Button className="bg-light" onClick={showModal}>
              Visit Site
            </Button>
            <Modal
              title={`${date}`}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button className="bg-light" key="back" onClick={handleCancel}>
                  Punch Out
                </Button>,
                <Button className="bg-dark" key="submit" onClick={handleOk}>
                  Punch In
                </Button>,
              ]}
            >
              <p>{name}</p>
            </Modal>
          </>
        )}
      </div>
      <Modal
        title={name}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
      <ViewModal datas={url} />
      </Modal>
    </div>
  );
};

export default Record;
