import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyC1PGtfrNwSI3cjafzCWWSTOd-5kAK1RV8",
  authDomain: "green-commercial-cleaning.firebaseapp.com",
  projectId: "green-commercial-cleaning",
  storageBucket: "green-commercial-cleaning.appspot.com",
  messagingSenderId: "51297464007",
  appId: "1:51297464007:web:59768b95020524c67b18e2",
});

export const auth = app.auth();
export const db = firebase.firestore();
export const storageRef = app.storage().ref();
// export const serverTimestamp = app.firestore.FieldValue.serverTimestamp();
export default firebase;
