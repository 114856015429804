import React from "react";
import "./ServiceListItem.css";

const ServiceListItem = ({ icon, header }) => {
  return (
    <div className="serviceListItem">
      <span className="serviceListItem__icon">{icon}</span>
      <p className="serviceListItem__header">{header}</p>
    </div>
  );
};

export default ServiceListItem;
