import React from "react";
import "./Welcome.css";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Welcome = ({
  welcome_text,
  button_text,
  account_text,
  link,
  loginImg,
}) => {
  return (
    <div className="welcome_container">
      <div className={ (link ==="login" ? "welcome_content1" :  "welcome_content")}>
        <div className="welcome_text">
          <h1>{welcome_text}</h1>
          <p>Let us better manage your cleaning needs.</p>
          {account_text && <p>{account_text}</p>}

          {button_text && (
            <Link to={link}>
              <Button className="btn-light">{button_text}</Button>
            </Link>
          )}
        </div>

        {loginImg && (
          <div className="welcome_image">
            <img src={loginImg} alt="login" width="101%" />
          </div>
        ) }
      </div>
    </div>
  );
};

export default Welcome;
