import React, { useState } from "react";
import { DashboardHeader } from "../../Dashboard";
import { Form, Button, Select, Input, message, Spin } from "antd";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState } from "draft-js";
import "./FeedbackAndReviews.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAuth } from "../../../../contexts/authcontexts";
import firebase, { db } from "../../../../firebase";

const FeedbackAndReviews = (props) => {
  const { currentUser } = useAuth();
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const feedbackSubmitHandler = (e) => {
    setIsSubmitted(true);
    // formatting feedback message
    let date = firebase.firestore.Timestamp.now();
    let feedbackmessagedata = {
      userRole: currentUser.claims.role,
      username: currentUser.claims.displayName,
      uploadedAt: date,
      FeedBackType: e.FeedBackType,
      FeedBack: e.FeedBack,
    };
    // Sending FeedBack
    db.collection("feedbacks")
      .doc()
      .set(feedbackmessagedata)
      .then(() => {
        setIsSubmitted(false);
        message.success("FeedBack Submitted Successfully.");
        form.resetFields();
      })
      .catch((err) => {
        message.error(err);
        setIsSubmitted(false);
      });
  };

  return (
    <>
      <div className="feedback_container">
        <DashboardHeader
          header="Feedback and Reviews"
          goBack={props.history.push}
          isNested
        />
        <div className="feedback_content">
          <Form form={form} onFinish={feedbackSubmitHandler}>
            <Form.Item
              name="FeedBackType"
              rules={[
                {
                  required: true,
                  message: "Please Select The Feedback Type!",
                },
              ]}
            >
              <Select
                size="large"
                style={{ fontSize: "14px" }}
                showSearch
                placeholder="Choose option"
                optionFilterProp="children"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Leave a Review">Leave a Review</Option>
                <Option value="Cleaner Feedback">Cleaner Feedback</Option>
                <Option value="Supervisor Feedback">Supervisor Feedback</Option>
                <Option value="Service Feedback">Service Feedback</Option>
                <Option value="IT Service Feedback">IT Service Feedback</Option>
                <Option value="Payment Feedback">Payment Feedback</Option>
                <Option value="Others Feedback">Others Feedback</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="FeedBack"
              rules={[
                {
                  required: true,
                  message: "Please enter your Feedback!",
                },
              ]}
            >
              <TextArea
                placeholder="Write down your feedback and reviews for us."
                showCount
                maxLength={250}
                rows={15}
              />
            </Form.Item>

            <Form.Item>
              <Button
                style={{
                  float: "right",
                  width: "10rem",
                  height: "2.5rem",
                  marginTop: "1rem",
                }}
                htmlType="submit"
                className="bg-dark"
                onSubmit={feedbackSubmitHandler}
              >
                {isSubmitted ? <Spin style={{ color: "white" }} /> : null}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FeedbackAndReviews;
