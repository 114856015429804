import React from "react";
import "./UploadDocument.css";
import { DashboardHeader, UploadFile } from "../../Dashboard";

const UploadDocument = (props) => {
  return (
    <div className="uploadDocument">
      <DashboardHeader
        header="Upload Document"
        goBack={props.history.push}
        isNested
      />
      <UploadFile />
    </div>
  );
};

export default UploadDocument;
