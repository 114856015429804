import React from "react";
import "./EndOfLease.css";
import {
  EndOfLeasePrimary,
  EndOfLeaseSecondary,
  EndOfLeaseCard,
  EndOfLeaseAccordion,
  BookingSummary,
} from "..";

const EndOfLease = () => {
  return (
    <div className="endOfLease">
      <div className="endOfLease__containerLeft">
        <EndOfLeasePrimary />
        <EndOfLeaseSecondary />
      </div>
      <div className="endOfLease__containerRight">
        <EndOfLeaseCard />
        <BookingSummary />
        <EndOfLeaseAccordion />
      </div>
    </div>
  );
};

export default EndOfLease;
